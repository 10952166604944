/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  CreateDelivery,
  DeleteDeliveryNote,
  GetAllDeliveryNotesByPO,
  GetReconcilationReport,
  UpdateDeliveryNote,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  NodataFound,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import ModalPdf from "../../../components/common/ModalPdf";
import ModalPdff from "../../../components/common/ModalPdff";

import { colors } from "../../../configs";
import { DeliveryNoteForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as strings, default as Strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_INVOICE_STATUS_FUNCTION,
  CREATE_INVOICE,
  UPDATE_INVOICE,
} from "../../../utils/constants";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import DeliveryNoteDwld from "./DeliveryNoteDwld";
import Poreconcilation from "./Poreconcilation";
import "./SearchInvoice.css";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const alertMessage = Strings["Invoice.AlertMessage"],
  alertTitle = Strings["Invoice.AlertTitle"];

const headCells = [
  {
    id: "delivery_number",
    numeric: false,
    disablePadding: true,
    label: "Delivery No.",
  },
  {
    id: "deliveryDt",
    numeric: false,
    disablePadding: false,
    label: "Delivery Date",
  },
  {
    id: "file",
    numeric: false,
    disablePadding: false,
    label: "Attachment",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const SearchDeliveryNote = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [payOpen, setPayOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [totalData, setTotalData] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [isDownloadReconciliationOpen, setIsDownloadReconciliationOpen] =
    React.useState(false);
  const [isDownloadOpen, setIsDownloadOpen] = React.useState(false);
  const [downloadData, setDownloadData] = React.useState();
  const [reconcilationReport, setReconcilationReport] = React.useState();
  const poDataType = localStorage.getItem("directPO");
  const [current, setCurrent] = useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_INVOICE)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_INVOICE)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_INVOICE_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);
  const location = useLocation();
  const fetchId = useParams();
  const paramId = isDecoded(fetchId.id);
  const purchase_id = parseInt(paramId);

  const proId = location.state
    ? location.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;
  const budget_id = location.state
    ? location.state.budget_id
    : location.query
    ? JSON.parse(location.query.params).budget_id
    : null;
  const rfqId = location.state
    ? location.state.rfqId
    : location.query
    ? JSON.parse(location.query.params).rfqId
    : null;
  const invoice = location.state
    ? location.state.invoice_id
    : location.query
    ? JSON.parse(location.query.params).invoice_id
    : null;

  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);
  const encodedRfqId = isEncoded(rfqId);
  const encodedPurchase = isEncoded(purchase_id);

  var paths = [];
  if (poDataType == "true") {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: `purchaseorders/${encodeBudId}`,
      },
      // {
      //   name: strings["Invoice"],
      //   path: `invoice/${encodedPurchase}`,
      // },
      {
        name: "Delivery Note",
        path: "/invoice",
      },
    ];
  } else {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["Rfq"],
        path: `rfq/${encodeBudId}`,
      },
      {
        name: strings["Quotation"],
        path: `quotation/${encodedRfqId}`,
      },
      {
        name: strings["costanlysisText"],
        path: `costanalysis/${encodedRfqId}`,
      },

      {
        name: "Cost Analysis Report",
        path: `searchcostreport/${encodedRfqId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: `purchaseorders/${encodeBudId}`,
      },
      // {
      //   name: strings["Invoice"],
      //   path: `invoice/${encodedPurchase}`,
      // },
      {
        name: "Delivery Note",
        path: "/invoice",
      },
    ];
  }
  const openAddModal = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setPayOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const getAllDeliveryNotes = () => {
    setisLoader(true);
    const id = purchase_id;
    GetAllDeliveryNotesByPO(id)
      .then((res) => {
        setDownloadData(res.data.data);
        const result = res.data.data.map((ele) => {
          return {
            id: ele.id,
            refNo: ele.reference_number,
            deliveryDt: ele.delivery_date,
            file: ele.file,
            delivery_number: ele.delivery_number,
            delivery_note: ele.comment,
          };
        });
        setRows(result);
        setisLoader(false);
      })
      .catch((err) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getAllDeliveryNotes();
  }, []);

  const createDeliveryNote = (formData) => {
    setisLoader(true);
    CreateDelivery(formData)
      .then((res) => {
        getAllDeliveryNotes();
        setisLoader(false);
        handleModalClose();
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <DeliveryNoteForm
        po_Id={purchase_id}
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleModalClose={handleModalClose}
        handleInvoiceData={
          clickedBtn === "add" ? createDeliveryNote : handleEdit
        }
        actionType={clickedBtn}
        initialValue={clickedBtn === "edit" ? current : {}}
      />
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    DeleteDeliveryNote(current.id)
      .then((res) => {
        setisLoader(false);
        getAllDeliveryNotes();
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
    setAlertBox(true);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const downloadModal = () => {
    return <DeliveryNoteDwld downloadData={downloadData} />;
  };
  const handleDownloadModalClose = () => {
    setIsDownloadOpen(false);
    setIsDownloadReconciliationOpen(false);
  };

  const handleDownload = (row) => {
    window.open(`${process.env.REACT_APP_BASE_URL2}${row.file}`, "Download");
  };

  const handleEdit = (formData) => {
    setLoading(true);
    UpdateDeliveryNote(formData, current.id)
      .then((res) => {
        getAllDeliveryNotes();
        setLoading(false);

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Updated Successfully",
            icon: "success",
          });
        }
        handleModalClose();
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.message);
      });
  };

  const handleChangeEdit = (row) => {
    setCurrent(row);
  };

  const handleDelete = (row) => {
    setAlertBox(true);
    setCurrent(row);
  };

  const reconsilation = (id) => {
    setisLoader(true);
    GetReconcilationReport(id)
      .then((res) => {
        setReconcilationReport(res.data.data);
        setisLoader(false);
        setIsDownloadReconciliationOpen(true);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  const handleReconsilation = (row) => {
    // window.open(`${process.env.REACT_APP_BASE_URL2}${row.file}`, 'Download')
    // alert('hello download',)
    // setIsDownloadOpen(true)
    return <Poreconcilation reconcilationReport={reconcilationReport} />;
  };

  return (
    <>
      <div className="container mb-5 pb-5">
        <Helmet>
          <title>Furnish | Delivery Note</title>
        </Helmet>
        {isLoader ? <LoaderComponent /> : null}
        <Modal
          onHandleClose={handleModalClose}
          isOpen={isOpen}
          contentStyle={{
            width: "90%",
            height: "90%",
          }}
        >
          {selectModal()}
        </Modal>
        <ModalPdf
          isOpen={isDownloadOpen}
          onHandleClose={handleDownloadModalClose}
        >
          {downloadModal()}
        </ModalPdf>
        <ModalPdff
          isOpen={isDownloadReconciliationOpen}
          onHandleClose={handleDownloadModalClose}
        >
          {handleReconsilation()}
        </ModalPdff>
        <div
          className="row mt-3"
          style={{
            alignItems: "center",
          }}
        >
          <div className="col-md-11">
            <h3> {"Delivery Note"} </h3>
            <BreadCrumbs
              pathList={paths}
              obj={{
                proId: proId,
                budget_id: budget_id,
                rfqId: rfqId,
                purchase_id: purchase_id
              }}
            />
          </div>
          <div className="col-md-1 d-flex justify-content-end mt-3">
            {isAdd && (
              <span className="addButton pt-1">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            )}
          </div>
        </div>

        {alertBox && (
          <AlertMessage
            title={alertTitle}
            message={alertMessage}
            onChangeCancel={handleChangeCancel}
            onChangeSucess={handleChangeSuccess}
          />
        )}
        {rows.length > 0 ? (
          <Box className="mt-5" sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) => {
                              handleClick(event, row.name);
                            }}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.delivery_number != null
                                ? row.delivery_number
                                : "NA"}
                            </TableCell>
                            <TableCell align="left">
                              {row.deliveryDt.split(" ")[0]}
                            </TableCell>
                            <TableCell align="left">
                              <Tooltip title="Download">
                                <DownloadIcon
                                  onClick={() => {
                                    handleDownload(row);
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell align="left">
                              {isUpdate &&
                              <>
                              <Tooltip title="Edit">
                                <EditIcon
                                  onClick={() => {
                                    setClickedBtn("edit");
                                    handleChangeEdit(row);
                                    openAddModal();
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title="Delete">
                                <DeleteIcon
                                  onClick={() => {
                                    handleDelete(row);
                                  }}
                                  sx={{ color: colors.danger }}
                                />
                              </Tooltip>
                              </>
                              }
                            </TableCell>

                            {/* <TableCell align="left">
                              <Tooltip title="Delivery Reconciliation">
                                <DownloadIcon
                                  onClick={() => {
                                    reconsilation(row.id);
                                  }}
                                />
                              </Tooltip>
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : (
          <>
            {isLoader ? (
              <LoaderComponent />
            ) : (
              <NodataFound
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SearchDeliveryNote;
