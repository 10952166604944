import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { PropagateLoader } from "react-spinners";
import { logoRect } from "../../../assets";
import { GetDeliveryReport } from "../../../axios";

const DeliveryDwnld = (props) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      height: "50%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-750px",
      fontSize: "8px",
    },
  });

  const { id } = props;
  const [shows, setShows] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);

  React.useEffect(() => {
    GetDeliveryReport(id)
      .then((res) => {
        setReportData(res.data.data);
        setShows(true);
      })
      .catch((err) => {
        setShows(false);
      });
  }, []);

  return (
    <>
      {shows === true ? (
        // <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
              <View fixed>
                <View style={styles.section} fixed>
                  <Image style={styles.image} src={logoRect} />
                  <Text style={{ fontSize: "12px", display: "flex" }}>
                    Delivery Report - {reportData.deliveryData[0].project.name}
                  </Text>
                </View>
              </View>
              <View style={{ marginLeft: "25px", marginRight: "25px" }}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        {reportData.deliveryData[0].project.name}
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        Furnish Demo
                      </Text>
                    </View>
                    <View
                      style={{
                        borderBottom: "1px solid black",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Budget Number
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData.deliveryData[0].serial_number +
                          " - " +
                          reportData.deliveryData[0].name}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Issuing Date
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {
                          reportData.deliveryData[0].budget_start_date.split(
                            " "
                          )[0]
                        }
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      backgroundColor: "#f2f2f2",
                      padding: "0px 3px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "8px",
                      }}
                    >
                      <View
                        style={{
                          width: "7%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Group
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "7%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Sub Group
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "10%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Category
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "8%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Control Number
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Reference
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "10%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Vendor
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "7%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Ship To Vendor
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "10%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          PO number
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "7%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Short Description
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "7%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Item Description
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "4%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Actual Qty
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "4%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Actual UOM
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "4%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Total Delivered
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "4%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Total Remaining
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "6%",
                          height: "100%",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Delivery Comments
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      padding: "0px 3px",
                    }}
                  >
                    {reportData.deliveryData[0].purchase_orderdelivery_details.map(
                      (ele) => {
                        return (
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              fontSize: "7px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <View
                              style={{
                                width: "7%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.item_group_name}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "7%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.itemsubgroup}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "10%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.itemcategory}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "8%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.control_number}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                N/A
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "10%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.vendor_name}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "7%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.vendor_ship_name}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "10%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.purchase_order_serial_number}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "7%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.name ? ele.name : "N/A"}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "7%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.description ? ele.description : "N/A"}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "4%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.actual_quantity}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "4%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {ele.UMO_name}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "4%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {parseInt(ele.totaldelivered_item)}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "4%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                {parseInt(ele.actual_quantity) -
                                  parseInt(ele.totaldelivered_item)}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "6%",
                                height: "100%",
                                padding: "3px",
                              }}
                            >
                              <Text
                                style={{
                                  margin: "auto",
                                  padding: "5px",
                                }}
                              >
                                N/A
                              </Text>
                            </View>
                          </View>
                        );
                      }
                    )}
                  </View>
                </View>
              </View>

              <View style={styles.footer} fixed>
                <View
                  style={{ borderTop: "1px solid black", marginRight: "50px" }}
                ></View>
                <View>
                  <Text style={{ fontSize: "8px" }}>
                    PO BOX 282247, Dubai, UAE
                  </Text>
                  {/* <Text style={{ fontSize: "8px" }}>
                      tel +971 4 3307725
                    </Text>
                    <Text style={{ fontSize: "8px" }}>
                      <Link style={{ color: "black" }} src="mailto:invitation@furnish-marketplace.com">
                        invitation@furnish-marketplace.com
                      </Link>
                    </Text>
                    <Text style={{ fontSize: "8px" }}>
                      <Link style={{ color: "black" }} src="http://furnish-marketplace.com">
                        furnish-marketplace.com
                      </Link>
                    </Text> */}
                </View>
                <View>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber, totalPages }) =>
                      `Page # ${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </View>
              </View>
            </Page>
          </Document>
        // </PDFViewer>
      ) : (
        <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
          <PropagateLoader color="blue" />
        </View>
      )}
    </>
  );
};

export default DeliveryDwnld;
