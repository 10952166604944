/* eslint-disable no-unused-vars */
import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import swal from "sweetalert";
import { ChangeVendorLoginStatus, GetAllVendor } from "../../../axios";
import {
  AlertMessage,
  BreadCrumbs,
  FormTextInput,
  Nodata,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import { CHANGE_STATUS_ROLE } from "../../../utils/constants";

export default function SearchVendorAccessPage() {
  const vendorAccess = Strings["VendorAcess.SearchVendorAccessHeader"],
    alertMessage = Strings["VendorAcess.AlertMessage"],
    alertTitle = Strings["VendorAcess.AlertTitle"],
    vendorName = Strings["VendorAcess.VendorName"],
    vendorEmail = Strings["VendorAcess.email"],
    activeStatus = Strings["VendorAcess.activeStatus"],
    action = Strings["VendorAcess.action"];

  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [statusCode, setStatusCode] = React.useState();
  const [isStatus, setIsStatus] = React.useState(true);
  const [totalData, setTotalData] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_STATUS_ROLE)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["UserRole"],
      path: "master-configuration/searchrole",
    },
  ];

  const mapableData = [
    {
      dataKey: "name",
      title: vendorName,
    },
    {
      dataKey: "email",
      title: vendorEmail,
    },
    {
      dataKey: "is_active",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangeActiveStatus = (id, status) => {
    setStatusCode(status);
    setStatusChangeId(id);
    setAlertBox(true);
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const getAllVendors = () => {
    setisLoader(true);
    GetAllVendor(rowsPerPage, page, searchValue.value)
      .then((res) => {
        setisLoader(false);
        const vendor_list = res.data.data.map((ele) => ({
          id: ele.id,
          name: ele.name,
          email: ele.email ? ele.email : "N/A",
          is_active: ele.login_access == null ? 0 : parseInt(ele.login_access),
        }));
        setRows(vendor_list);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response);
      });
  };

  React.useEffect(() => {
    getAllVendors();
  }, [rowsPerPage, page]);

  const handleChangeSuccess = () => {
    setisLoader(true);
    ChangeVendorLoginStatus(statusChangeId)
      .then((res) => {
        getAllVendors();
        if (res.data.message == "Email Required .") {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "error",
          });
        } else {
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
        }
        setisLoader(false);
      })
      .catch((e) => {
        swal({
          title: "Error!",
          text: e.response.data.message,
          icon: "success",
        });
        setisLoader(false);
      });
    setAlertBox(false);
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setisLoader(true);
      GetAllVendor(rowsPerPage, page, searchValue.value)
        .then((res) => {
          setisLoader(false);
          const vendor_list = res.data.data.map((ele) => ({
            id: ele.id,
            name: ele.name,
            email: ele.email ? ele.email : "N/A",
            is_active:
              ele.login_access == null ? 0 : parseInt(ele.login_access),
          }));
          setRows(vendor_list);
          setTotalData(res.data.total);
        })
        .catch((e) => {
          setisLoader(false);
          setMessage(e.response);
        });
    }

    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAllVendors();
  };
  return (
    <>
      <div className="container">
        {isLoader ? <LoaderComponent /> : null}
        <div className="row mt-4">
          <div className="col">
            <h3> {vendorAccess} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          <div className="row">
            {alertBox && (
              <AlertMessage
                title={alertTitle}
                message={alertMessage}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}
            <div className="col-12">
              <div className="UserbudgetTable">
                <form onSubmit={handleSearchSubmit}>
                  <FormTextInput
                    type="text"
                    className="searchBarUser mb-3"
                    placeholder={`Search `}
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    inputStyle={{ border: "none" }}
                    editable
                  />
                </form>
                {rows.length > 0 ? (
                  <>
                    <SearchTable
                      isStatus={isStatus}
                      placeholder="Search ..."
                      data={rows}
                      mapableData={mapableData}
                      searchProp={["name", "email"]}
                      handleChangeActiveStatus={handleChangeActiveStatus}
                    />
                    <TablePagination
                      rowsPerPageOptions={[5, 25, 50]}
                      component="div"
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <>{isLoader ? <LoaderComponent /> : <Nodata />}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
