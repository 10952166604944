/* eslint-disable no-unused-vars */
import TablePagination from "@mui/material/TablePagination";
import React from "react";
import swal from "sweetalert";
import {
  CreatetCategorySubProductMapping,
  GetAllCategorySubProductMapping,
  UpdatetCategorySubProductMapping,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchMappingTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import {
  CreateVendorCategoryToProductMappingForm,
  DeleteVendorCategoryToProductMappingForm,
} from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CREATE_CATEGORY_PRODUCT_MAPPING_FUNCTION,
  UPDATE_CATEGORY_PRODUCT_MAPPING_FUNCTION,
} from "../../../utils/constants";

const converter = (rows) => {
  // let result = "";
  // rows.forEach((data) => {
  //   if (result.length === 0) {
  //     result = result + data.productName;
  //   } else {
  //     result = result + "," + data.productName;
  //   }
  // });
  // return result;
};

function SearchVendorCategoryToProductMappingPage() {
  const searchSubCategoryTitle = Strings["VendorProductMapping.Dashboard"],
    alertMessage = Strings["VendorProductMapping.AlertMessage"],
    alertTitle = Strings["VendorProductMapping.AlertTitle"],
    category = Strings["VendorProductMapping.TableHead.category"],
    subCategory = Strings["VendorProductMapping.TableHead.subCategory"],
    products = Strings["VendorProductMapping.TableHead.products"],
    activeStatus = Strings["VendorProductMapping.TableHead.activeStatus"],
    action = Strings["VendorProductMapping.TableHead.action"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentCatMapping, setCurrentCatMapping] = React.useState({});
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_CATEGORY_PRODUCT_MAPPING_FUNCTION)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_CATEGORY_PRODUCT_MAPPING_FUNCTION)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["VendorCategoryToProductMapping"],
      path: "master-configuration/searchvendorcategorytoproductmapping",
    },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };

  const getAllVendorCategorySubCategoryMapping = () => {
    setLoading(true);
    GetAllCategorySubProductMapping(rowsPerPage, page, searchValue.value)
      .then((res) => {
        setLoading(false);
        setRows(res.data.data);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getAllVendorCategorySubCategoryMapping();
  }, [rowsPerPage, page]);

  const createCategoryMapping = (formData) => {
    setLoading(true);
    let data = {
      category_id: formData.category_id.value,
      sub_category_id: formData.sub_category_id.value,
      product_ids: formData.product_ids,
    };
    CreatetCategorySubProductMapping(data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          getAllVendorCategorySubCategoryMapping();
          handleModalClose();
        }
      })
      .catch((e) => {
        setMessage(e.response.data.message);
        setLoading(false);
      });
  };
  const updateCategoryMapping = (formData) => {
    let data = {
      sub_category_id: formData.sub_category_id.key,
      product_ids: formData.product_ids,
    };
    UpdatetCategorySubProductMapping(data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
        }
        getAllVendorCategorySubCategoryMapping();
        handleModalClose();
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    if (clickedBtn === "add") {
      return (
        <CreateVendorCategoryToProductMappingForm
          errorMessage={errorMessage}
          handleResultData={createCategoryMapping}
          actionType="add"
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <CreateVendorCategoryToProductMappingForm
          errorMessage={errorMessage}
          handleResultData={updateCategoryMapping}
          initialValue={currentType}
          actionType="edit"
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "view") {
      return (
        <DeleteVendorCategoryToProductMappingForm
          deleteChangeFormData={() => {}}
          initialValue={currentCatMapping}
          actionType={"view"}
        />
      );
    } else if (clickedBtn === "delete") {
      return (
        <DeleteVendorCategoryToProductMappingForm
          deleteChangeFormData={() => {}}
          initialValue={currentCatMapping}
          actionType={"delete"}
        />
      );
    }
  };

  const getData = (rows) => {
    const result = rows.map((data) => {
      return {
        ...data,
        products: converter(data.products),
      };
    });
    return result;
  };
  const searchTableData = getData(rows);

  const mapableData = [
    {
      dataKey: "categories[0].category",
      title: category,
    },
    {
      dataKey: "sub_category",
      title: subCategory,
    },
    {
      dataKey: "subcategoriesproduct[0].productName",
      title: products,
    },
    {
      dataKey: "is_active",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    handleSelectBtn("edit");
  };

  const handleChangeActiveStatus = (id) => {
    setStatusChangeId(id);
    setAlertBox(true);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    const result = rows.map((rowData) => {
      if (rowData.id === statusChangeId) {
        return { ...rowData, activeStatus: !rowData.activeStatus };
      } else {
        return { ...rowData };
      }
    });
    setRows(result);
    setAlertBox(false);
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setLoading(true);
      GetAllCategorySubProductMapping(rowsPerPage, page, searchData.value)
        .then((res) => {
          setLoading(false);
          setRows(res.data.data);
          setTotalData(res.data.total);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAllVendorCategorySubCategoryMapping();
  };
  return (
    <>
      <div className="container">
        {loading ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {searchSubCategoryTitle}</h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          <div className="row">
            {alertBox && (
              <AlertMessage
                title={alertTitle}
                message={alertMessage}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}
            <div className="col-12">
              <div className="UserbudgetTable">
                <form onSubmit={handleSearchSubmit}>
                  <FormTextInput
                    type="text"
                    className="searchBarUser mb-3"
                    placeholder={`Search `}
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    inputStyle={{ border: "none" }}
                    editable
                  />
                </form>
                {rows.length !== 0 ? (
                  <>
                    <SearchMappingTable
                      isUpdate={isUpdate}
                      placeholder={"Vendor Category"}
                      data={searchTableData}
                      mapableData={mapableData}
                      searchProp={["sub_category"]}
                      handleChangeActiveStatus={handleChangeActiveStatus}
                      handleChangeEdit={handleChangeEdit}
                    />
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <>
                    {loading ? (
                      <LoaderComponent />
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}

export default SearchVendorCategoryToProductMappingPage;
