import React from "react";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import { GetAssetByBudgetId } from "../../../axios";
import { BreadCrumbs, DownloadIcon, IconButton } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import strings from "../../../translations";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import "./AssetRegister.css";
import { getItemFromStorage } from "../../../utils/storage";

const AssetRegister = () => {
  const [isLoader, setisLoader] = React.useState(false);
  const [rseArray, setRseArray] = React.useState([]);
  const location = useLocation();
  let proId = location.state
    ? location.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;

  const fetchId = useParams();
  const paramId = isDecoded(fetchId.id);
  const BudgtId = parseInt(paramId);

  let budget_id = location?.state ? location.state.budget_id : BudgtId;

 

  let scope_id = location?.state ? location?.state?.scope_id : location?.query
  ? JSON.parse(location.query.params).scope_id
  : null;
  let budget_Name = location?.state ? location.state?.budget_name
    : location?.query ? JSON.parse(location.query.params).budget_name
    : null;

  if(scope_id == null || scope_id == undefined && budget_Name == null || budget_Name == undefined && proId == null || proId == undefined && budget_id == null || budget_id == undefined){
      const paramData = JSON.parse(getItemFromStorage("PARAMS"))
      if(paramData){
          budget_id = paramData.budget_id;
          proId = paramData.proId;
          budget_Name = paramData.budget_Name;
          scope_id =  paramData.budget_scope;
      }
  }
  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);

  const getData = () => {
    setisLoader(true);
    GetAssetByBudgetId(budget_id)
      .then((res) => {
        const result = res.data.data[0].purchase_order_budget_item_details.map(
          (elm) => ({
            itemsubgroup: elm.itemsubgroup ? elm.itemsubgroup : "N/A",
            purchase_order_name: elm.purchase_order_name
              ? elm.purchase_order_name
              : "N/A",
            serial_number: elm.control_number,
            itemcategory: elm.itemcategory ? elm.itemcategory : "N/A",
            description: elm.description ? elm.description : "N/A",
            image: elm.file_path
              ? `${process.env.REACT_APP_BASE_URL2}/${elm.file_path}`
              : "N/A",
          })
        );
        console.log(result);
        setisLoader(false);
        setRseArray(result);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  const SearchAssetRegister = strings["SearchAssetRegister.header"];

  const headers = [
    { label: "subgroup", key: "itemsubgroup" },
    { label: "purchase order name", key: "purchase_order_name" },
    { label: "serial number", key: "serial_number" },
    { label: "item category", key: "itemcategory" },
    { label: "description", key: "description" },
    { label: "image", key: "image" },
  ];

  const csvReport = {
    data: rseArray,
    headers: headers,
    filename: "Asset_Report.csv",
  };

  const paths = [
    {
      name: strings["Projects"],
      path: "project",
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: strings["AssetRegister"],
      path: "assetregister",
    },
  ];

  return (
    <div className="content-wrapper p-0">
      <Helmet>
        <title>Furnish | Asset Register</title>
      </Helmet>
      {isLoader ? <LoaderComponent /> : null}
      <div
        className="row align-items-center mt-4"
        style={{
          alignItems: "center",
        }}
      >
        <div className="col">
          <h3 className="">{SearchAssetRegister}</h3>
          <BreadCrumbs
            pathList={paths}
            obj={{
              proId: proId,
              budget_id: budget_id,
              budget_name: budget_Name,
              scope_id: scope_id
            }}
          />
        </div>
        <div className="col-auto">
          <div>
            <span className="downloadButton" style={{}}>
              <CSVLink style={{ color: "white" }} {...csvReport}>
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </CSVLink>
            </span>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <table className="table table-image">
            <thead>
              <tr>
                <th scope="col">Sl. No.</th>
                <th scope="col">Subgroup</th>
                <th scope="col">PO Name</th>
                <th scope="col">Control No.</th>
                <th scope="col">Category</th>
                <th scope="col">Description</th>
                <th scope="col">Image</th>
              </tr>
            </thead>
            <tbody>
              {rseArray.map((ele, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{ele.itemsubgroup}</td>
                      <td>{ele.purchase_order_name}</td>
                      <td>{ele.serial_number}</td>
                      <td>{ele.itemcategory}</td>
                      <td>{ele.description}</td>
                      <td className="w-25">
                        <img
                          src={ele.image}
                          className="img-fluid img-thumbnail"
                          alt="image"
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AssetRegister;
