/* eslint-disable no-unused-vars */
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import {
  AddIcon,
  BreadCrumbs,
  FormTextInput,
  Modal,
  NodataFound,
  SearchMappingTable,
} from "../../../components";
import "../../../styles/tablepagination.css";
import Strings from "../../../translations";

import TablePagination from "@mui/material/TablePagination";
import swal from "sweetalert";
import {
  CreateItemCategorySubCategory,
  GetAllItemCategorySubCategoryMap,
  UpdateItemCategorySubCategory,
} from "../../../axios";
import LoaderComponent from "../../../components/common/Loader";
import CreateMappingItemCategorySubCategoryForm from "../../../forms/Masters/CreateItemCategorySubCategoryMapping/CreateMappingItemCategorySubCategoryForm";
import strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CREATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING,
  UPDATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING,
} from "../../../utils/constants";

export default function SearchItemCategorySubCategoryMapping() {
  const searchMapingItemCategoryTitle =
      Strings["ItemCategorySubCategory.searchMapingItemTitle"],
    alertMessage = Strings["RoleFunctionMapping.AlertMessage"],
    alertTitle = Strings["RoleFunctionMapping.AlertTitle"],
    roleName = Strings["RoleFunctionMapping.TableHead.roleName"],
    functionName = Strings["RoleFunctionMapping.TableHead.functionName"],
    activeStatus = Strings["RoleFunctionMapping.TableHead.activeStatus"],
    action = Strings["RoleFunctionMapping.TableHead.action"],
    category = Strings["ItemCategorySubCategoryMapping.TableHead.category"],
    subCategory =
      Strings["ItemCategorySubCategoryMapping.TableHead.subcategory"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);

  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: Strings["ItemCategoryMapping"],
    },
  ];

  const mapableData = [
    {
      dataKey: "name",
      title: category,
    },
    {
      dataKey: "subcategories",
      title: subCategory,
    },
    {
      dataKey: "is_active",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];
  const getAllItemMap = () => {
    setLoading(true);
    GetAllItemCategorySubCategoryMap(rowsPerPage, page, searchValue.value)
      .then((res) => {
        setLoading(false);
        setRows(res.data.data);
        setTotalData(res.data.total);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getAllItemMap();
  }, [rowsPerPage, page]);

  const createRoleFunction = (formData) => {
    setLoading(true);
    CreateItemCategorySubCategory(formData)
      .then((res) => {
        getAllItemMap();
        setLoading(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Created Succesfully",
            icon: "success",
          });
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };

  const updateRoleFunction = (formData) => {
    setLoading(true);
    UpdateItemCategorySubCategory(formData)
      .then((res) => {
        getAllItemMap();
        setLoading(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Updated Sucessfully",
            icon: "success",
          });
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.message);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const selectModal = () => {
    if (clickedBtn === "add") {
      return (
        <CreateMappingItemCategorySubCategoryForm
          errorMessage={errorMessage}
          setMessage={setMessage}
          handleResultData={createRoleFunction}
          actionType="add"
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <CreateMappingItemCategorySubCategoryForm
          errorMessage={errorMessage}
          setMessage={setMessage}
          handleResultData={updateRoleFunction}
          actionType="edit"
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    }
  };

  const converter = (rows) => {
    let result = "";
    rows.forEach((data) => {
      if (result.length === 0) {
        result = result + data.name;
      } else {
        result = result + "," + data.name;
      }
    });
    return result;
  };

  const getData = (rows) => {
    const result = rows.map((data) => {
      return {
        ...data,
        subcategories: converter(data.subcategories),
      };
    });
    return result;
  };

  const searchTableData = getData(rows);

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    handleSelectBtn("edit");
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setLoading(true);
      GetAllItemCategorySubCategoryMap(rowsPerPage, page, searchData.value)
        .then((res) => {
          setLoading(false);
          setRows(res.data.data);
          setTotalData(res.data.total);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAllItemMap();
  };
  return (
    <>
      <div className="container">
        {loading ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {searchMapingItemCategoryTitle} </h3>

            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          {/* {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )} */}

          <div className="row">
            <div className="col-12">
              <div className="UserbudgetTable">
                <form onSubmit={handleSearchSubmit}>
                  <FormTextInput
                    type="text"
                    className="searchBarUser mb-3"
                    placeholder={`Search `}
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    inputStyle={{ border: "none" }}
                    editable
                  />
                </form>
                {rows.length !== 0 ? (
                  <>
                    <SearchMappingTable
                      isUpdate={isUpdate}
                      isStatus={isStatus}
                      placeholder={"Item Category"}
                      data={searchTableData}
                      mapableData={mapableData}
                      searchProp={["name"]}
                      handleChangeEdit={handleChangeEdit}
                    />
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <>
                    {loading ? (
                      <LoaderComponent />
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}
