/* eslint-disable no-unused-vars */
import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import swal from "sweetalert";
import {
  CreateBudgetScopeItemCategoryMap,
  GetAllBudgetScopeItemCategoryMap,
  UpdateBudgetScopeItemCategoryMap,
} from "../../../axios";
import {
  AddIcon,
  BreadCrumbs,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchMappingTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import {
  CreateBudgetScopeToItemCategoryMappingForm,
  DeleteBudgetScopeToItemCategoryMappingForm,
} from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CREATE_BUDGET_ITEM_CATEGORY_MAPPING,
  UPDATE_BUDGET_ITEM_CATEGORY_MAPPING,
} from "../../../utils/constants";

const converter = (rows) => {
  let result = "";
  rows.forEach((data) => {
    if (result.length === 0) {
      result = result + data.name;
    } else {
      result = result + "," + data.name;
    }
  });
  return result;
};

export default function SearchBudgetScopeToItemCategoryMapping() {
  const searchTitle = Strings["BudgetMasterConfiguration.DashboardTitle"],
    budgetScope = Strings["BudgetMasterConfiguration.TableHead.budgetScope"],
    itemCategory = Strings["BudgetMasterConfiguration.TableHead.itemCategory"],
    budgetScopeSearch = Strings["BudgetScopeItemMap.TableSearch.itemCategory"],
    action = Strings["BudgetMasterConfiguration.TableHead.action"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [errorMessage, setMessage] = React.useState(null);
  const [currentUserMapping] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [uid, setUid] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_BUDGET_ITEM_CATEGORY_MAPPING)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_BUDGET_ITEM_CATEGORY_MAPPING)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);
  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["BudgetScopeToItemCategoryMapping"],
      path: "master-configuration/budgetitemcategorymapping",
    },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const getAllBudgetScopeItemCategory = () => {
    setLoading(true);
    GetAllBudgetScopeItemCategoryMap(page, rowsPerPage, searchValue.value)
      .then((res) => {
        setLoading(false);
        setRows(res.data.data);
        setTotalData(res.data.total);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getAllBudgetScopeItemCategory();
  }, [page, rowsPerPage]);
  const createBudgetItemMap = (formData) => {
    setLoading(true);
    let data = {
      budget_scope_id: formData.budget_scope_id.value,
      item_category_ids: formData.item_category_ids,
    };
    CreateBudgetScopeItemCategoryMap(data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          getAllBudgetScopeItemCategory();
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };
  const updateBudgetItemMap = (formData) => {
    setLoading(true);
    let data = {
      budget_scope_id: formData.budget_scope_id.key,
      item_category_ids: formData.item_category_ids,
    };
    UpdateBudgetScopeItemCategoryMap(data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
        }
        getAllBudgetScopeItemCategory();
        handleModalClose();
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    if (clickedBtn === "add") {
      return (
        <CreateBudgetScopeToItemCategoryMappingForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleResultData={createBudgetItemMap}
          actionType="add"
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <CreateBudgetScopeToItemCategoryMappingForm
          errorMessage={errorMessage}
          setMessage={setMessage}
          handleResultData={updateBudgetItemMap}
          handleModalClose={handleModalClose}
          initialValue={currentType}
          actionType="edit"
          uid={uid}
        />
      );
    } else {
      return (
        <DeleteBudgetScopeToItemCategoryMappingForm
          deleteChangeFormData={(val) => {}}
          initialValue={currentUserMapping}
          actionType={clickedBtn}
        />
      );
    }
  };

  const getData = (rows) => {
    const result = rows.map((data) => {
      return {
        ...data,
        item_category: converter(data.item_category),
      };
    });
    return result;
  };

  const searchTableData = getData(rows);

  const mapableData = [
    {
      dataKey: "name",
      title: budgetScope,
    },
    {
      dataKey: "item_category",
      title: itemCategory,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id.id;
    });
    setCurrentType(result);
    handleSelectBtn("edit");
    setUid(result.id);
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setLoading(true);
      GetAllBudgetScopeItemCategoryMap(page, rowsPerPage, searchData.value)
        .then((res) => {
          setLoading(false);
          setRows(res.data.data);
          setTotalData(res.data.total);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAllBudgetScopeItemCategory();
  };
  return (
    <>
      <div className="container">
        {loading ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {searchTitle} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="UserbudgetTable">
              <form onSubmit={handleSearchSubmit}>
                <FormTextInput
                  type="text"
                  className="searchBarUser mb-3"
                  placeholder={`Search `}
                  Value={searchValue.value}
                  onChangeText={handleSearch}
                  inputStyle={{ border: "none" }}
                  editable
                />
              </form>
              {rows.length !== 0 ? (
                <>
                  <SearchMappingTable
                    isUpdate={isUpdate}
                    placeholder={budgetScopeSearch}
                    data={searchTableData}
                    mapableData={mapableData}
                    searchProp={["name"]}
                    handleChangeEdit={handleChangeEdit}
                  />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={totalData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <>
                  {loading ? (
                    <LoaderComponent />
                  ) : (
                    <NodataFound
                      onClick={() => {
                        openAddModal();
                        handleSelectBtn("add");
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
