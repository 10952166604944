/* eslint-disable no-unused-vars */
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { Collapse } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import {
  ChangeVendorStatus,
  CreateUserRoleMap,
  CreateVendor,
  GetAllCountryList,
  GetAllVendor,
  GetAllVendorCompanyType,
  GetTotalVendors,
  UpdateVendorManagement,
  UploadVendor,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  DownloadIcon,
  IconButton,
  Modal,
  NodataFound,
  SearchProfileTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import { VendorForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_VENDOR_STATUS_FUNCTION,
  CREATE_VENDOR,
  UPDATE_VENDOR,
} from "../../../utils/constants";
import VendorUploadFile from "./VendorUploadFile";

export default function SearchVendorPage() {
  const searchFunctionTitle = Strings["VenderManagement.Label"],
    alertMessage = Strings["VenderManagement.AlertMessage"],
    alertTitle = Strings["VenderManagement.AlertTitle"],
    vendor = Strings["VenderManagement.TableHead.vendor"],
    type = Strings["VenderManagement.TableHead.type"],
    tel = Strings["VenderManagement.TableHead.tel"],
    activeStatus = Strings["VenderManagement.TableHead.activeStatus"],
    action = Strings["VenderManagement.TableHead.action"];
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isShow, setIsShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currenttype, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [code, setCode] = React.useState();
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [uid, setUid] = React.useState();
  const [countryDropdown, setCountryDropdown] = React.useState([]);
  const [companyTypeDropdown, setCompanyTypeDropdown] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [csvData, setCsvData] = React.useState([]);

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_VENDOR)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_VENDOR)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_VENDOR_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["Vendor"],
      path: "master-configuration/vendor",
    },
  ];

  const mapableData = [
    {
      dataKey: "name",
      title: vendor,
    },
    {
      dataKey: "company_type.company_type",
      title: type,
    },
    {
      dataKey: "phone",
      title: tel,
    },
    {
      dataKey: "is_active",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };
  const openShowModal = () => {
    setIsShow(true);
  };
  const handleUploadClose = () => {
    setMessage(null);
    setIsShow(false);
  };

  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };

  const getAllVendor = () => {
    const value = searchdropdown === "country" ? optionDropdown : searchInput;
    const body = {
      [searchdropdown]: value ? value.toString() : null,
    };
    setLoading(true);
    const searchList = open === true ?  searchdropdown !=  null ? body : "" : "";
    GetAllVendor(rowsPerPage, page, searchName,searchList)
      .then((res) => {
        setLoading(false);
        const data = res.data.data;
        setRows(data);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const getTotalVendor = () => {
    setLoading(true);
    GetTotalVendors()
      .then((res) => {
        setLoading(false);
        const data = res.data.data;
        setCsvData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getTotalVendor();
  }, []);

  React.useEffect(() => {
    getAllVendor();
  }, [rowsPerPage, page, open]);

  React.useEffect(() => {
    GetAllCountryList()
      .then((res) => {
        setOptionDropdown(res.data.data);
      })
      .catch(() => {});
  }, []);

  const createVendor = (formData) => {
    setLoading(true);
    CreateVendor(formData)
      .then((res) => {
        const roleData = {
          identity_id: res.data.data.authdata.id,
          role_id: formData.role_id,
        };
        if (res.data.data.authdata == "") {
          swal({
            title: "Success!",
            text: "Created Succesfully",
            icon: "success",
          });
        } else {
          CreateUserRoleMap(roleData)
            .then((res) => {
              setLoading(false);
              swal({
                title: "Success!",
                text: "Created Succesfully",
                icon: "success",
              });
            })
            .catch((e) => {
              setLoading(false);
              setMessage(e.response.data.message);
            });
        }
        setLoading(false);
        getAllVendor();
        handleModalClose();
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };
  const updateVendor = (formData) => {
    setLoading(true);
    UpdateVendorManagement(formData, uid)
      .then((res) => {
        const roleData = {
          identity_id: res.data.data.authdata.id,
          role_id: formData.role_id,
        };
        if (res.data.data.authdata == "") {
          swal({
            title: "Success!",
            text: "Updated Succesfully",
            icon: "success",
          });
        } else {
          CreateUserRoleMap(roleData)
            .then((res) => {
              setLoading(false);
              swal({
                title: "Success!",
                text: "Updated Succesfully",
                icon: "success",
              });
            })
            .catch((e) => {
              setLoading(false);
              setMessage(e.response.data.message);
            });
        }
        getAllVendor();
        handleModalClose();
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };
  React.useEffect(() => {
    GetAllCountryList()
      .then((res) => {
        setCountryDropdown(res.data.data);
      })
      .catch(() => {});
  }, []);
  React.useEffect(() => {
    setLoading(true);
    GetAllCountryList()
      .then((res) => {
        setLoading(false);
        setCountryDropdown(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  React.useEffect(() => {
    setLoading(true);
    GetAllVendorCompanyType()
      .then((res) => {
        setLoading(false);
        setCompanyTypeDropdown(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const uploadVendor = (formData) => {
    setLoading(true);
    UploadVendor(formData)
      .then((res) => {
        getAllVendor();
        handleUploadClose();
        setLoading(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((err) => {
        setLoading(false);
        setMessage(err.response.data.message);
      });
  };

  const uploadModal = () => {
    return (
      <VendorUploadFile
        handleModalClose={handleUploadClose}
        handleChangeData={uploadVendor}
        errorMessage={errorMessage}
        setMessage={setMessage}
      />
    );
  };

  const selectModal = () => {
    return (
      <VendorForm
        handleChangeFormData={
          clickedBtn === "add" ? createVendor : updateVendor
        }
        errorMessage={errorMessage}
        setMessage={setMessage}
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currenttype : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  const handleChangeActiveStatus = (id, activeStatus) => {
    setCode(activeStatus);
    setAlertBox(true);
    setStatusChangeId(id);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setLoading(true);
    ChangeVendorStatus({ status: code === 0 ? 1 : 0 }, statusChangeId)
      .then((res) => {
        const result = rows.map((ele) => {
          if (ele.id === res.data.data.id) {
            return {
              ...ele,
              is_active: res.data.data.is_active,
            };
          } else {
            return ele;
          }
        });
        setRows(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setAlertBox(false);
  };

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setUid(result.id);
    setCurrentType(result);
    handleSelectBtn("edit");
  };

  let history = useHistory();
  const handleView = (id) => {
    const result = rows.find((row) => {
      return row.id === id;
    });
    history.push({
      pathname: "/master-configuration/vendorprofile",
      state: result,
    });
    setCurrentType(result);
  };

  const [searchInput, setSearchInput] = React.useState("");
  const [searchName, setSearchName] = React.useState("");
  const [searchdropdown, setSearchdropdown] = React.useState(null);
  const [optionDropdown, setOptionDropdown] = React.useState();

  let disableSearch = searchInput === "" || searchdropdown === undefined;

  const headers = [
    { label: "Name", key: "name" },
    { label: "Address", key: "address" },
    { label: "Postcode", key: "postcode" },
    { label: "City", key: "city" },
    { label: "Country", key: "countries.name" },
    { label: "ContactName", key: "vendor_contact[0].name" },
    { label: "ContactPhone", key: "vendor_contact[0].telephone" },
    { label: "ContactMobile", key: "vendor_contact[0].mobile" },
    { label: "ContactEmail", key: "vendor_contact[0].email" },
  ];
  const csvReport = {
    data: csvData,
    headers: headers,
    filename: "Vendor_Report.csv",
  };
  const handleClose = () => {
    setOpen(!open);
    setSearchInput("");
    setSearchdropdown("");
    setSearchName("");
  };

  const handleDataUpload = (e) => {
    openShowModal();
  };

  return (
    <>
      <Helmet>
        <title>Furnish | Vendors</title>
      </Helmet>
      <div className="content-wrapper p-0">
        {loading ? <LoaderComponent /> : null}
        <Modal
          onHandleClose={handleModalClose}
          isOpen={isOpen}
          contentStyle={{ width: "80%" }}
        >
          {selectModal()}
        </Modal>
        <Modal
          onHandleClose={handleUploadClose}
          isOpen={isShow}
          contentStyle={{ width: "50%" }}
        >
          {uploadModal()}
        </Modal>
        <div
          className="row mt-4"
          style={{
            alignItems: "center",
          }}
        >
          <div className="col-4">
            <h3> {searchFunctionTitle} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          <div className="col-8">
            <div
              className="row"
              style={{
                alignItems: "center",
              }}
            >
              <div className="col-10 text-end">
                <span
                  style={{
                    marginRight: "-11px",
                  }}
                >
                  <span
                    className="addButton"
                    style={{
                      marginRight: "0px",
                      display: open === true ? "none" : "inline",
                    }}
                  >
                    <IconButton>
                      <Tooltip title="Filter">
                        <FilterAltIcon
                          onClick={() => setOpen(!open)}
                          style={{
                            cursor: "pointer",
                            fill: colors.primary,
                            width: "20px",
                            height: "20px",
                            display: open === true ? "none" : "inline",
                          }}
                        />
                      </Tooltip>
                    </IconButton>
                    </span>
               
                </span>
              </div>
              {isAdd && (
                <div className="col-1">
                  <span className="addButton">
                    <IconButton
                      onClick={() => {
                        openAddModal();
                        handleSelectBtn("add");
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </span>
                </div>
              )}
              <div className="col-1">
                <span className="downloadButton">
                  <CSVLink style={{ color: "white" }} {...csvReport}>
                    <IconButton>
                      <DownloadIcon />
                    </IconButton>
                  </CSVLink>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{"marginTop": "20px"}}>
        <Collapse in={open}>
                    <div id="example-collapse-text">
                      <div className="row ">
                        <div className="col-sm-3">
                          <div className="dropdown">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setSearchdropdown(e.target.value)
                              }
                              value={searchdropdown}
                            >
                              <option selected>Select </option>
                              <option value="city">City</option>
                              <option value="country">Country</option>
                            </select>
                          </div>
                        </div>
                        {searchdropdown === "city" ? (
                          <div className="col-sm-2">
                            <input
                              className="form-control me-2"
                              type="search"
                              placeholder="City Name"
                              aria-label="Search"
                              value={searchInput}
                              onChange={(e) => setSearchInput(e.target.value)}
                            />
                          </div>
                        ) : null}
                        {searchdropdown === "country" ? (
                          <div className="col-sm-2">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setOptionDropdown(e.target.value)
                              }
                              value={optionDropdown}
                            >
                              <option value="">Select</option>
                              {countryDropdown.map((elem) => {
                                return (
                                  <option value={elem.id}>{elem.name}</option>
                                );
                              })}
                            </select>
                          </div>
                        ) : null}
                         <div className="col-sm-2">
                            <input
                              className="form-control me-2"
                              type="search"
                              placeholder="Company Name"
                              aria-label="Search"
                              value={searchName}
                              onChange={(e) => setSearchName(e.target.value)}
                            />
                          </div>
                        {/* {searchdropdown === "name" ? (
                          <div className="dropdown">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setOptionDropdown(e.target.value)
                              }
                              value={optionDropdown}
                            >
                              <option value="">Select</option>
                              {companyTypeDropdown.map((elem) => {
                                return (
                                  <option value={elem.company_type}>
                                    {elem.company_type}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : null} */}
                        <div className="col-sm-3">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={getAllVendor}
                            style={{"marginRight":"10px"}}
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                 
        </div>
        <div className="row ">
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}
          <div className="col-12">
            <div
              className="UserbudgetTable"
              style={{
                marginBottom: "50px",
              }}
            >
              {rows.length > 0 ? (
                <>
                  <SearchProfileTable
                    placeholder={"vendor"}
                    data={rows}
                    mapableData={mapableData}
                    searchProp={["name"]}
                    handleChangeActiveStatus={handleChangeActiveStatus}
                    handleChangeEdit={handleChangeEdit}
                    handleView={handleView}
                  />
                  <TablePagination
                    rowsPerPageOptions={[5, 25, 50]}
                    component="div"
                    count={totalData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <>
                  {loading ? (
                    <LoaderComponent />
                  ) : (
                    <NodataFound
                      onClick={() => {
                        openAddModal();
                        handleSelectBtn("add");
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
