import { axios } from ".";

const Login = (body) => {
  return axios.post("authservice/public/api/login", body);
};
const Logout = () => {
  return axios.delete("authservice/public/api/logout");
};
const ChangePassword = (id, data) => {
  return axios.patch(`authservice/public/api/password/change/${id}`, data);
};

const ForgetPassword = (body) => {
  return axios.post("authservice/public/api/password/forgot", body);
};
const ConfirmForgetPassword = (otp, body) => {
  return axios.patch(`authservice/public/api/password/forgot/${otp}`, body);
};

const UpdateProfile = (id) => {
  return axios.get(`project/public/api/master/user/getauthdata/${id}`);
};

const GetUserProfile = () => {
  return axios.get(`project/public/api/master/user-profile/getAll`);
};
const UpdateUserProfile = (body, id) => {
  return axios.post(
    `project/public/api/master/user-profile/update/${id}`,
    body
  );
};

const GetAllUserType = (row, page, search) => {
  return axios.get(
    `project/public/api/master/user-type/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetUserTypeDropdown = () => {
  return axios.get(`project/public/api/master/user-type/getAll`);
};

const CreateUserType = (body) => {
  return axios.post("project/public/api/master/user-type/create", body);
};
const UpdateUserType = (body, id) => {
  return axios.post(`project/public/api/master/user-type/update/${id}`, body);
};
const UpdateActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/user-type/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusUserType = () => {
  return axios.get(`project/public/api/master/user-type/getAllActive`);
};

//operator
const GetAllOperator = (row, page, search) => {
  return axios.get(
    `project/public/api/master/operator/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateOperator = (body) => {
  return axios.post("project/public/api/master/operator/create", body);
};
const UpdateOperator = (body, id) => {
  return axios.post(`project/public/api/master/operator/update/${id}`, body);
};
const UpdateOperatorActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/operator/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusOperator = () => {
  return axios.get(`project/public/api/master/operator/getAllActive`);
};

const GetAllClientType = (row, page, search) => {
  return axios.get(
    `project/public/api/master/client-type/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateClientType = (body) => {
  return axios.post("project/public/api/master/client-type/create", body);
};
const UpdateClientType = (body, id) => {
  return axios.post(`project/public/api/master/client-type/update/${id}`, body);
};
const UpdateClientTypeStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/client-type/changeStatus/${id}`,
    body
  );
};
const GetAllActiveClientType = () => {
  return axios.get("project/public/api/master/client-type/getAllActive");
};

const GetClientManagement = () => {
  return axios.get("api");
};
const CreateClientManagement = (body) => {
  return axios.post("api", body);
};
const UpdateClientManagement = (body, id) => {
  return axios.post(`api/${id}`, body);
};
const UpdateClientManagementStatus = (body, id) => {
  return axios.post(`api/${id}`, body);
};

const GetEstablishment = (row, page, search) => {
  return axios.get(
    `project/public/api/master/establishment/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateEstablishment = (body) => {
  return axios.post("project/public/api/master/establishment/create", body);
};
const UpdateEstablishment = (body, id) => {
  return axios.post(
    `project/public/api/master/establishment/update/${id}`,
    body
  );
};
const UpdateEstablishmentStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/establishment/changeStatus/${id}`,
    body
  );
};
const GetAllActiveEstablishment = () => {
  return axios.get("project/public/api/master/establishment/getAllActive");
};

const GetVendorCategory = (row, page, search) => {
  return axios.get(
    `project/public/api/master/vendor-category/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateVendorCategory = (body) => {
  return axios.post("project/public/api/master/vendor-category/create", body);
};
const UpdateVendorCategory = (body, id) => {
  return axios.post(
    `project/public/api/master/vendor-category/update/${id}`,
    body
  );
};
const UpdateVendorCategoryStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/vendor-category/changeStatus/${id}`,
    body
  );
};
const GetAllActiveVendorCategory = () => {
  return axios.get("project/public/api/master/vendor-category/getAllActive");
};

const GetVendorSubcategory = (row, page, search) => {
  return axios.get(
    `project/public/api/master/vendor-subcategory/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateVendorSubCategory = (body) => {
  return axios.post(
    "project/public/api/master/vendor-subcategory/create",
    body
  );
};
const UpdateVendorSubCategory = (body, id) => {
  return axios.post(
    `project/public/api/master/vendor-subcategory/update/${id}`,
    body
  );
};
const UpdateVendorSubCategoryStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/vendor-subcategory/changeStatus/${id}`,
    body
  );
};
const GetAllActiveVendorSubCategory = () => {
  return axios.get("project/public/api/master/vendor-subcategory/getAllActive");
};

const GetSubmittals = () => {
  return axios.get("project/public/api/master/required-submittals/getAll");
};
const CreateSubmittals = (body) => {
  return axios.post(
    "project/public/api/master/required-submittals/create",
    body
  );
};
const UpdateSubmittals = (body, id) => {
  return axios.post(
    `project/public/api/master/required-submittals/update/${id}`,
    body
  );
};
const UpdateSubmittalsStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/required-submittals/changeStatus/${id}`,
    body
  );
};

const GetCountryType = (row, page, search) => {
  return axios.get(
    `project/public/api/master/country/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetAllCountryList = () => {
  return axios.get(`project/public/api/master/country/getAll`);
};

const CreateCountryType = (body) => {
  return axios.post("project/public/api/master/country/create", body);
};
const UpdatedCountryType = (body, id) => {
  return axios.post(`project/public/api/master/country/update/${id}`, body);
};
const UpdatedInvoicePayment = (body, id) => {
  return axios.post(`project/public/api/invoice-payment/update/${id}`, body);
};
const GetAllActiveCountry = () => {
  return axios.get("project/public/api/master/country/getAllActive");
};
const UpdateCountryTypeActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/country/changeStatus/${id}`,
    body
  );
};

const GetModeOfShipment = (row, page, search) => {
  return axios.get(
    `project/public/api/master/shipment-mode/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateModeOfShipment = (body) => {
  return axios.post("project/public/api/master/shipment-mode/create", body);
};
const UpdateModeOfShipment = (body, id) => {
  return axios.post(
    `project/public/api/master/shipment-mode/update/${id}`,
    body
  );
};
const UpdateModeOfShipmentStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/shipment-mode/changeStatus/${id}`,
    body
  );
};
const GetAllClient = () => {
  return axios.post("project/public/api/master/client/getAll");
};

const GetAllClientPagination = (row, page, search, body) => {
  return axios.post(
    `project/public/api/master/client/getAll?perpage=${row}&page=${page}&search=${search}`,
    body
  );
};
const GetAllClientBySearch = (keys, value) => {
  return axios.get(`project/public/api/master/client/getAll?${keys}=${value}`);
};
const CreateClient = (body) => {
  return axios.post("project/public/api/master/client/create", body);
};
const UploadClient = (body) => {
  return axios.post("project/public/api/master/client/createImport", body);
};
const UpdateClient = (body, id) => {
  return axios.post(`project/public/api/master/client/update/${id}`, body);
};
const UpdateClientStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/client/changeStatus/${id}`,
    body
  );
};
const GetAllActiveClient = () => {
  return axios.get("project/public/api/master/client/getAllActive");
};

const GetAllUOM = (row, page, search) => {
  return axios.get(
    `project/public/api/master/uom/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateUOM = (body) => {
  return axios.post("project/public/api/master/uom/create", body);
};
const UpdateUOM = (body, id) => {
  return axios.post(`project/public/api/master/uom/update/${id}`, body);
};
const UpdateUOMStatus = (body, id) => {
  return axios.post(`project/public/api/master/uom/changeStatus/${id}`, body);
};
const GetAllActiveUOM = () => {
  return axios.get("project/public/api/master/uom/getAllActive");
};
const GetAllSchedule = (row, page, search) => {
  return axios.get(
    `project/public/api/master/schedule/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateSchedule = (body) => {
  return axios.post("project/public/api/master/schedule/create", body);
};
const UpdateSchedule = (body, id) => {
  return axios.post(`project/public/api/master/schedule/update/${id}`, body);
};
const UpdateScheduleStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/schedule/changeStatus/${id}`,
    body
  );
};
const GetAllVendorCategorySubCategory = (row, page, search) => {
  return axios.get(
    `project/public/api/master/vendor-category-mapping/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateAllVendorCategorySubCategory = (body) => {
  return axios.post(
    "project/public/api/master/vendor-category-mapping/create",
    body
  );
};
const UpdateVendorCategorySubCategory = (body) => {
  return axios.post(
    "project/public/api/master/vendor-category-mapping/update",
    body
  );
};
const GetBudgetReport = (id) => {
  return axios.get(`project/public/api/budget/budget-item/getAllStatus/${id}`);
};
const GetItemReport = (id) => {
  return axios.get(
    `project/public/api/budget/item-schedule/getAllStatus/${id}`
  );
};
const GetBudget = (row, page, search) => {
  return axios.get(
    `project/public/api/master/budget-scope/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateBudget = (body) => {
  return axios.post("project/public/api/master/budget-scope/create", body);
};
const UpdateBudget = (body, id) => {
  return axios.post(
    `project/public/api/master/budget-scope/update/${id}`,
    body
  );
};
const UpdateBudgetStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/budget-scope/changeStatus/${id}`,
    body
  );
};

const GetAccessedConsideration = (row, page, search) => {
  return axios.get(
    `project/public/api/master/accessed-consideration/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateAccessedConsideration = (body) => {
  return axios.post(
    "project/public/api/master/accessed-consideration/create",
    body
  );
};
const UpdatedAccessedConsideration = (body, id) => {
  return axios.post(
    `project/public/api/master/accessed-consideration/update/${id}`,
    body
  );
};
const UpdateAccessedConsiderationActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/accessed-consideration/changeStatus/${id}`,
    body
  );
};

//Currency
const CreateCurrency = (body) => {
  return axios.post("project/public/api/master/currency/create", body);
};
const GetCurrency = (row, page, search) => {
  return axios.get(
    `project/public/api/master/currency/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const UpdatedCurrency = (body, id) => {
  return axios.post(`project/public/api/master/currency/update/${id}`, body);
};
const UpdateCurrencyActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/currency/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusCurrency = () => {
  return axios.get(`project/public/api/master/currency/getAllActive`);
};

//
const CreateFinalPaymentAgainst = (body) => {
  return axios.post(
    "project/public/api/master/final-payment-against/create",
    body
  );
};
const GetFinalPaymentAgainst = (row, page, search) => {
  return axios.get(
    `project/public/api/master/final-payment-against/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};

const GetActiveFinalPaymentAgainst = () => {
  return axios.get(
    `project/public/api/master/final-payment-against/getAllActive`
  );
};

const UpdatedFinalPaymentAgainst = (body, id) => {
  return axios.post(
    `project/public/api/master/final-payment-against/update/${id}`,
    body
  );
};
const UpdateFinalPaymentAgainstActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/final-payment-against/changeStatus/${id}`,
    body
  );
};

const GetIncoterm = (row, page, search) => {
  return axios.get(
    `project/public/api/master/incoterm/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateIncoterm = (body) => {
  return axios.post("project/public/api/master/incoterm/create", body);
};
const UpdatedIncoterm = (body, id) => {
  return axios.post(`project/public/api/master/incoterm/update/${id}`, body);
};
const UpdateIncotermActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/incoterm/changeStatus/${id}`,
    body
  );
};
const GetAllActiveIncoterm = () => {
  return axios.get("project/public/api/master/incoterm/getAllActive");
};

const CreateItemGroup = (body) => {
  return axios.post("project/public/api/master/item-group/create", body);
};
const GetItemGroup = (row, page, search) => {
  return axios.get(
    `project/public/api/master/item-group/getAll?page=${row}&perpage=${page}&search=${search}`
  );
};
const UpdatedItemGroup = (body, id) => {
  return axios.post(`project/public/api/master/item-group/update/${id}`, body);
};
const UpdateItemGroupActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/item-group/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusItemGroup = () => {
  return axios.get(`project/public/api/master/item-group/getAllActive`);
};
//
const CreateLocation = (body) => {
  return axios.post("project/public/api/master/location/create", body);
};
const GetLocation = (row, page, search) => {
  return axios.get(
    `project/public/api/master/location/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};

const UpdatedLocation = (body, id) => {
  return axios.post(`project/public/api/master/location/update/${id}`, body);
};
const UpdateLocationActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/location/changeStatus/${id}`,
    body
  );
};

//
const GetModeOfPayment = (row, page, search) => {
  return axios.get(
    `project/public/api/master/mode-of-payment/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateModeOfPayment = (body) => {
  return axios.post("project/public/api/master/mode-of-payment/create", body);
};
const UpdatedModeOfPayment = (body, id) => {
  return axios.post(
    `project/public/api/master/mode-of-payment/update/${id}`,
    body
  );
};
const UpdateModeOfPaymentActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/mode-of-payment/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusModeOfPayment = () => {
  return axios.get(`project/public/api/master/mode-of-payment/getAllActive`);
};

const GetPaymentTerms = (row, page, search) => {
  return axios.get(
    `project/public/api/master/payment-terms/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreatePaymentTerms = (body) => {
  return axios.post("project/public/api/master/payment-terms/create", body);
};
const UpdatedPaymentTerms = (body, id) => {
  return axios.post(
    `project/public/api/master/payment-terms/update/${id}`,
    body
  );
};
const UpdatePaymentTermsActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/payment-terms/changeStatus/${id}`,
    body
  );
};
const GetAllActivePaymentTerms = () => {
  return axios.get("project/public/api/master/payment-terms/getAllActive");
};

const GetPaymentProgress = (row, page, search) => {
  return axios.get(
    `project/public/api/master/payment-progress/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetActivePaymentProgress = () => {
  return axios.get(`project/public/api/master/payment-progress/getAllActive`);
};
const CreatePaymentProgress = (body) => {
  return axios.post("project/public/api/master/payment-progress/create", body);
};
const UpdatedPaymentProgress = (body, id) => {
  return axios.post(
    `project/public/api/master/payment-progress/update/${id}`,
    body
  );
};
const UpdatePaymentProgressActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/payment-progress/changeStatus/${id}`,
    body
  );
};

const GetProductServices = (row, page, search) => {
  return axios.get(
    `project/public/api/master/product_services/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateProductServices = (body) => {
  return axios.post("project/public/api/master/product_services/create", body);
};
const UpdatedProductServices = (body, id) => {
  return axios.post(
    `project/public/api/master/product_services/update/${id}`,
    body
  );
};
const UpdateProductServicesActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/product_services/changeStatus/${id}`,
    body
  );
};
const GetAllActiveProducts = () => {
  return axios.get("project/public/api/master/product_services/getAllActive");
};
const GetProjectScope = (row, page, search) => {
  return axios.get(
    `project/public/api/master/project-scope/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateProjectScope = (body) => {
  return axios.post("project/public/api/master/project-scope/create", body);
};
const UpdatedProjectScope = (body, id) => {
  return axios.post(
    `project/public/api/master/project-scope/update/${id}`,
    body
  );
};
const UpdateProjectScopeActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/project-scope/changeStatus/${id}`,
    body
  );
};
const GetAllActiveProjectScope = () => {
  return axios.get("project/public/api/master/project-scope/getAllActive");
};
const GetPublicArea = (row, page, search) => {
  return axios.get(
    `project/public/api/master/public-area/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreatePublicArea = (body) => {
  return axios.post("project/public/api/master/public-area/create", body);
};
const UpdatedPublicArea = (body, id) => {
  return axios.post(`project/public/api/master/public-area/update/${id}`, body);
};
const UpdatePublicAreaActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/public-area/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusPublicArea = () => {
  return axios.get(`project/public/api/master/public-area/getAllActive`);
};
const GetRequiredDocument = (row, page, search) => {
  return axios.get(
    `project/public/api/master/required-document/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetAllActiveRequiredDocument = () => {
  return axios.get("project/public/api/master/required-document/getAllActive");
};
const CreateRequiredDocument = (body) => {
  return axios.post("project/public/api/master/required-document/create", body);
};
const UpdatedRequiredDocument = (body, id) => {
  return axios.post(
    `project/public/api/master/required-document/update/${id}`,
    body
  );
};
const UpdateRequiredDocumentActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/required-document/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusRequiredDocument = () => {
  return axios.get(`project/public/api/master/required-document/getAllActive`);
};
const GetRequiredSubmittals = (row, page, search) => {
  return axios.get(
    `project/public/api/master/required-submittals/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateRequiredSubmittals = (body) => {
  return axios.post(
    "project/public/api/master/required-submittals/create",
    body
  );
};
const UpdatedRequiredSubmittals = (body, id) => {
  return axios.post(
    `project/public/api/master/required-submittals/update/${id}`,
    body
  );
};
const UpdateRequiredSubmittalsActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/required-submittals/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusRequiredSubmittals = () => {
  return axios.get(
    `project/public/api/master/required-submittals/getAllActive`
  );
};
const CreateRoomType = (body) => {
  return axios.post("project/public/api/master/room-type/create", body);
};
const GetAllRoomType = (row, page, search) => {
  return axios.get(
    `project/public/api/master/room-type/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const UpdatedRoomType = (body, id) => {
  return axios.post(`project/public/api/master/room-type/update/${id}`, body);
};
const UpdateRoomTypeActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/room-type/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusRoomType = () => {
  return axios.get(`project/public/api/master/room-type/getAllActive`);
};

const GetStage = (row, page, search) => {
  return axios.get(
    `project/public/api/master/stage/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetAllActiveStage = () => {
  return axios.get(`project/public/api/master/stage/getAllActive`);
};
const CreateStage = (body) => {
  return axios.post("project/public/api/master/stage/create", body);
};
const UpdatedStage = (body, id) => {
  return axios.post(`project/public/api/master/stage/update/${id}`, body);
};
const UpdateStageActiveStatus = (body, id) => {
  return axios.post(`project/public/api/master/stage/changeStatus/${id}`, body);
};
const GetAllStageActiveStatus = () => {
  return axios.get(`project/public/api/master/stage/getAllActive`);
};

const GetTransportationMode = (row, page, search) => {
  return axios.get(
    `project/public/api/master/transportation-mode/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetAllActiveTransportation = () => {
  return axios.get(
    "project/public/api/master/transportation-mode/getAllActive"
  );
};
const CreateTransportationMode = (body) => {
  return axios.post(
    "project/public/api/master/transportation-mode/create",
    body
  );
};
const UpdatedTransportationMode = (body, id) => {
  return axios.post(
    `project/public/api/master/transportation-mode/update/${id}`,
    body
  );
};
const UpdateTransportationModeActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/transportation-mode/changeStatus/${id}`,
    body
  );
};
const GetVendorCompanyType = (row, page, search) => {
  return axios.get(
    `project/public/api/master/company-type/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetAllVendorCompanyType = (row, page) => {
  return axios.get(`project/public/api/master/company-type/getAll`);
};
const GetVendorCompanyList = () => {
  return axios.get(`project/public/api/master/company-type/getAll`);
};
const CreateVendorCompanyType = (body) => {
  return axios.post("project/public/api/master/company-type/create", body);
};
const UpdatedVendorCompanyType = (body, id) => {
  return axios.post(
    `project/public/api/master/company-type/update/${id}`,
    body
  );
};
const UpdateVendorCompanyTypeActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/company-type/changeStatus/${id}`,
    body
  );
};
const AllActiveStatusCompanyType = () => {
  return axios.get("project/public/api/master/company-type/getAllActive");
};
const Getcommodity = (row, page, search) => {
  return axios.get(
    `project/public/api/master/commodity/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const Createcommodity = (body) => {
  return axios.post("project/public/api/master/commodity/create", body);
};
const Updatedcommodity = (body, id) => {
  return axios.post(`project/public/api/master/commodity/update/${id}`, body);
};
const UpdatecommodityActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/commodity/changeStatus/${id}`,
    body
  );
};
const GetitemCategory = (row, page, search) => {
  return axios.get(
    `project/public/api/master/item-category/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateitemCategory = (body) => {
  return axios.post("project/public/api/master/item-category/create", body);
};
const UpdateditemCategory = (body, id) => {
  return axios.post(
    `project/public/api/master/item-category/update/${id}`,
    body
  );
};
const UpdateitemCategoryActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/item-category/changeStatus/${id}`,
    body
  );
};

const GetAllCategorySubProductMapping = (row, page, search) => {
  return axios.get(
    `project/public/api/master/product-subcategory-mapping/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreatetCategorySubProductMapping = (body) => {
  return axios.post(
    "project/public/api/master/product-subcategory-mapping/create",
    body
  );
};
const UpdatetCategorySubProductMapping = (body) => {
  return axios.post(
    "project/public/api/master/product-subcategory-mapping/update",
    body
  );
};
//
const GetAllBudgetScopeItemCategoryMap = (row, page, search) => {
  return axios.get(
    `project/public/api/master/budget-scope-item-category-mapping/getAll?perpage=${page}&page=${row}&search=${search}`
  );
};
const CreateBudgetScopeItemCategoryMap = (body) => {
  return axios.post(
    "project/public/api/master/budget-scope-item-category-mapping/create",
    body
  );
};
const UpdateBudgetScopeItemCategoryMap = (body) => {
  return axios.post(
    "project/public/api/master/budget-scope-item-category-mapping/update",
    body
  );
};

const GetItemCategoryByBudgetScope = (id) => {
  return axios.get(
    `project/public/api/master/budget-scope-item-category-mapping/get/${id}`
  );
};

const GetAllBudgetScope = () => {
  return axios.get("project/public/api/master/budget-scope/getAllActive");
};
const GetAllActiveItemCategory = () => {
  return axios.get("project/public/api/master/item-category/getAllActive");
};

const GetUserManagement = (row, page, search, body) => {
  return axios.post(
    `project/public/api/master/user/getAll?perpage=${row}&page=${page}&search=${search}`,
    body
  );
};
const GetUsers = () => {
  return axios.post(`project/public/api/master/user/getAll`);
};

const GetUserManagementBySearch = (row, page, body) => {
  return axios.post(
    `project/public/api/master/user/getAll?perpage=${row}&page=${page}`,
    body
  );
};

const CreateUserManagement = (body) => {
  return axios.post("project/public/api/master/user/create", body);
};
const UpdatedUserManagement = (body, id) => {
  return axios.post(`project/public/api/master/user/update/${id}`, body);
};
const UpdateUserManagementActiveStatus = (body, id) => {
  return axios.post(`project/public/api/master/user/changeStatus/${id}`, body);
};
const GetAllActiveUserManagement = () => {
  return axios.get("project/public/api/master/user/getAllActive");
};
const GetUserManagementById = (id) => {
  return axios.get(`project/public/api/master/user/get/${id}`);
};
const GetTotalVendors = () => {
  return axios.post(`project/public/api/master/vendor/getAll`);
};
const GetVendorById = (id) => {
  return axios.get(`project/public/api/master/vendor/get/${id}`);
};
const GetVendorByEmail = (body) => {
  return axios.post("project/public/api/master/vendor/getbyemail", body);
};

const GetAllVendor = (row, page, search, body) => {
  const keys = Object.keys(body);
  const value = Object.values(body);
  return axios.post(
    `project/public/api/master/vendor/getAll?perpage=${row}&page=${page}&search=${search}&${keys[0]}=${value[0]}`,
    body
  );
};
const getAllVendorBySearch = (key, value) => {
  return axios.post(`project/public/api/master/vendor/getAll?${key}=${value}`);
};
const GetAllActiveVendor = () => {
  return axios.get("project/public/api/master/vendor/getAllActive");
};
const GetAllActiveCommodities = () => {
  return axios.get("project/public/api/master/commodity/getAllActive");
};
const CreateVendor = (body) => {
  return axios.post("project/public/api/master/vendor/create", body);
};
const UploadVendor = (body) => {
  return axios.post("project/public/api/master/vendor/createImport", body);
};
const getVendorByLineItem = (budgetId) => {
  return axios.get(`project/public/api/budget/budget-item/getAllVendors/${budgetId}`);
};
const getAllActiveVendor = () => {
  return axios.get("project/public/api/master/vendor/getAllActive");
};
const ChangeVendorStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/vendor/changeStatus/${id}`,
    body
  );
};
const UpdateVendorManagement = (body, id) => {
  return axios.post(`project/public/api/master/vendor/update/${id}`, body);
};
const GetAllBranches = (id) => {
  return axios.get(`project/public/api/master/branch/getAll/${id}`);
};
const GetAllActiveBranches = () => {
  return axios.get("project/public/api/master/branch/getAllActive");
};
const CreateBranch = (body) => {
  return axios.post("project/public/api/master/branch/create", body);
};
const UpdateBranch = (body, id) => {
  return axios.post(`project/public/api/master/branch/update/${id}`, body);
};
const ChangeBranchStatus = (id, body) => {
  return axios.post(
    `project/public/api/master/branch/changeStatus/${id}`,
    body
  );
};
const GetVendorContact = (id) => {
  return axios.get(`project/public/api/master/vendor-contact/getAll/${id}`);
};
const CreateVendorContact = (body) => {
  return axios.post("project/public/api/master/vendor-contact/create", body);
};
const UpdateVendorContact = (body, id) => {
  return axios.post(
    `project/public/api/master/vendor-contact/update/${id}`,
    body
  );
};
const StatusChangeVendorContact = (body, id) => {
  return axios.post(
    `project/public/api/master/vendor-contact/changeStatus/${id}`,
    body
  );
};

const CreateProject = (body) => {
  return axios.post("project/public/api/project/create", body);
};
const GetReportData = () => {
  return axios.get("project/public/api/project/getAllStatus");
};
const GetOngoingProject = (row, page) => {
  return axios.get(
    `project/public/api/project/getAllOngoing?perpage=${row}&page=${page}`
  );
};
const GetProjectById = (id) => {
  return axios.get(`project/public/api/project/get/${id}`);
};

const GetAllProjectStatusByVendor = (id) => {
  return axios.get(`project/public/api/project/getByVendor/${id}`);
};

const AllOngoingProject = () => {
  return axios.get(`project/public/api/project/getAllOngoing`);
};
const GetAllProject = () => {
  return axios.get("project/public/api/project/getAll");
};
const GetProjectSpecificUsers = (id) => {
  return axios.get(`project/public/api/project/getAllProjectSpecificUsers/${id}`);
};
const GetProjectSpecificApprovers = (id) => {
  return axios.get(`project/public/api/project/getAllProjectSpecificApprovers/${id}`);
};
const GetCompleteProject = (row, page) => {
  return axios.get(
    `project/public/api/project/getAllCompleted?perpage=${row}&page=${page}`
  );
};
const AllCompleteProjects = () => {
  return axios.get(`project/public/api/project/getAllCompleted`);
};
const GetAllActiveProject = (row, page) => {
  return axios.get(
    `project/public/api/project/getAllActive?perpage=${row}&page=${page}`
  );
};
const GetAllProjects = () => {
  return axios.get(`project/public/api/project/getAllActive`);
};
const GetAllDraftProject = (row, page) => {
  return axios.get(
    `project/public/api/project/getAllDraft?perpage=${row}&page=${page}`
  );
};
const GetAllDrafts = () => {
  return axios.get(`project/public/api/project/getAllDraft`);
};
const AllDeactiveProject = (row, page) => {
  return axios.get(
    `project/public/api/project/getAllDeactive?perpage=${row}&page=${page}`
  );
};
const GetAllDeactiveProject = () => {
  return axios.get("project/public/api/project/getAllDeactive");
};

const ChangeActiveStatusProject = (id, body) => {
  return axios.post(`project/public/api/project/changeStatus/${id}`, body);
};
const UpdateProject = (body, id) => {
  return axios.post(`project/public/api/project/update/${id}`, body);
};

const ImageCreation = (body) => {
  return axios.post("project/public/api/master/files/create", body);
};

const GetClientContact = (id) => {
  return axios.get(`project/public/api/master/clientContact/getAll/${id}`);
};
const CreateClientContact = (body) => {
  return axios.post("project/public/api/master/clientContact/create", body);
};
const UpdatedClientContact = (body, id) => {
  return axios.post(
    `project/public/api/master/clientContact/update/${id}`,
    body
  );
};
const UpdateClientContactActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/clientContact/changeStatus/${id}`,
    body
  );
};
const GetAllClientContactsById = (id) => {
  return axios.get(`project/public/api/master/clientContact/get/${id}`);
};

const GetAllActiveClientContact = () => {
  return axios.get("project/public/api/master/clientContact/getAllActive");
};
const GetAllItemSubmittals = () => {
  return axios.get("project/public/api/budget/item-submittal/getAll");
};

const GetActiveRequiredSubmittals = () => {
  return axios.get(
    "project/public/api/master/required-submittals/getAllActive"
  );
};
const CreateItemSubmittals = (body) => {
  return axios.post("project/public/api/budget/item-submittal/create", body);
};
const UpdateItemSubmittals = (id, body) => {
  return axios.post(
    `project/public/api/budget/item-submittal/update/${id}`,
    body
  );
};
const UpdateStatusItemSubmittals = (body, id) => {
  return axios.post(
    `project/public/api/budget/item-submittal/changeStatus/${id}`,
    body
  );
};

const GetProjectBudget = (id, row, page) => {
  return axios.get(
    `project/public/api/budget/getAll/${id}?perpage=${row}&page=${page}`
  );
};
const CreateProjectBudget = (body) => {
  return axios.post("project/public/api/budget/create", body);
};
const UpdateProjectBudget = (body, id) => {
  return axios.post(`project/public/api/budget/update/${id}`, body);
};
const GetAllActiveProjectBudget = () => {
  return axios.get("project/public/api/budget/getAllActive");
};
const ChangeActiveStatusProjectBudget = (id, body) => {
  return axios.post(`project/public/api/budget/changeStatus/${id}`, body);
};

const GetBudgetSchedule = () => {
  return axios.get("project/public/api/budget/budget-schedule/getAllActive");
};
const CreateBudgetSchedule = (body) => {
  return axios.post("project/public/api/budget/budget-schedule/create", body);
};
const UpdateBudgetSchedule = (body, id) => {
  return axios.post(
    `project/public/api/budget/budget-schedule/update/${id}`,
    body
  );
};
const GetAllActiveBudgetSchedule = () => {
  return axios.get("project/public/api/budget/budget-schedule/getAllActive");
};
const ChangeActiveStatusBudgetSchedule = (id) => {
  return axios.post(
    `project/public/api/budget/budget-schedule/deactivate/${id}`,
    {}
  );
};
const GetBudgetScheduleById = (id) => {
  return axios.get(
    `project/public/api/budget/budget-schedule/changeStatus/${id}`
  );
};

const GetBudgetItem = () => {
  return axios.get("project/public/api/budget/budget-item/getAll");
};
const CreateBudgetItem = (body) => {
  return axios.post("project/public/api/budget/budget-item/create", body);
};
const UpdateBudgetItem = (body, id) => {
  return axios.post(`project/public/api/budget/budget-item/update/${id}`, body);
};
const GetAllBudgetItemById = (id) => {
  return axios.get(
    `project/public/api/budget/budget-item/getAllActive/${id}
  `
  );
};
const GetAllBudgetFilteItemById = (
  selectGroup,
  selectCategory,
  selectSubGroup,
  searchValue,
  vendorId,
  id,
  page,
  row,
  search
) => {
  return axios.get(
    `project/public/api/budget/budget-item/getAllActive/${id}?item_group_id=${selectGroup}&item_catagory_id=${selectCategory}&item_subgroup_id=${selectSubGroup}&control_number=${searchValue}&vendor_id=${vendorId}&page=${page}&limit=${row}&search=${search}`
  );
};

const DeleteBudgetItem = (id) => {
  return axios.post(
    `project/public/api/budget/budget-item/changeStatus/${id}`,
    {}
  );
};
const GetBudgetItemById = (id) => {
  return axios.get(`project/public/api/budget/budget-item/changeStatus/${id}`);
};

const GetItemSchedule = (id) => {
  return axios.get(
    `project/public/api/budget/item-schedule/getAllActive/${id}`
  );
};
const CreateItemSchedule = (body) => {
  return axios.post("project/public/api/budget/item-schedule/create", body);
};
const UpdateItemSchedule = (id, body) => {
  return axios.post(
    `project/public/api/budget/item-schedule/update/${body}`,
    id
  );
};

const DeleteItemSchedule = (id) => {
  return axios.post(
    `/project/public/api/budget/item-schedule/deactivate/${id}`,
    {},
    id
  );
};

const CreateRFI = (body) => {
  return axios.post(
    "project/public/api/quotation/request-for-information/create",
    body
  );
};
const CreateRFQ = (body) => {
  return axios.post(
    "project/public/api/quotation/request-for-quotation/create",
    body
  );
};
const GetitemsubCategory = (row, page, search) => {
  return axios.get(
    `project/public/api/master/item-sub-category/getAll?perpage=${page}&page=${row}&search=${search}`
  );
};
const CreateitemsubCategory = (body) => {
  return axios.post("project/public/api/master/item-sub-category/create", body);
};
const UpdateditemsubCategory = (body, id) => {
  return axios.post(
    `project/public/api/master/item-sub-category/update/${id}`,
    body
  );
};
const UpdateitemsubCategoryActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/item-sub-category/changeStatus/${id}`,
    body
  );
};

const GetAllRFI = (id, row, page) => {
  return axios.get(
    `project/public/api/quotation/request-for-information/getAllActive/${id}?perpage=${row}&page=${page}`
  );
};
const GetAllActiveRfi = (id) => {
  return axios.get(
    `project/public/api/quotation/request-for-information/getAllActive/${id}`
  );
};
const GetAllRFQ = (id, row, page) => {
  return axios.get(
    `project/public/api/quotation/request-for-quotation/getAllActive/${id}?perpage=${row}&page=${page}`
  );
};
const GetAllActiveRfq = (id) => {
  return axios.get(
    `project/public/api/quotation/request-for-quotation/getAllActive/${id}`
  );
};
const GetAllRFQVendor = (id, row, page) => {
  return axios.get(
    `project/public/api/quotation/request-for-quotation/getAllByVendor/${id}?perpage=${row}&page=${page}`
  );
};

const GetAllRFQByVendor = (id, row, page) => {
  return axios.get(
    `project/public/api/quotation/request-for-quotation/getAllByVendor/${id}`
  );
};

const ChangeRFQStatus = (id) => {
  return axios.post(
    `project/public/api/quotation/request-for-quotation/changeStatus/${id}`,
    {}
  );
};
const GetRFQById = (id, row, page) => {
  return axios.get(
    `project/public/api/quotation/request-for-quotation/get/${id}?perpage=${row}&page=${page}`
  );
};
const GetAllHistoryRFQById = (id) => {
  return axios.get(
    `project/public/api/quotation/request-for-quotation/getallhistory/${id}`
  );
};
const GetAllActivePurchaseOrder = (id) => {
  return axios.get(`project/public/api/purchase-order/getAllActive`);
};
const UpdatePurchaseOrderActiveStatus = (id) => {
  return axios.post(`project/public/api/purchase-order/changeStatus/${id}`, {});
};
const UpdateCommentDeliveryDate = (id, body) => {
  return axios.post(
    `project/public/api/purchase-order/updateComment/${id}`,
    body
  );
};

const UpdatePurchaseOrder = (id, body) => {
  return axios.post(`project/public/api/purchase-order/update/${id}`, body);
};
const CreatePurchaseOrder = (body) => {
  return axios.post("project/public/api/purchase-order/create", body);
};

const UpdateOrderStatus = (id, body) => {
  return axios.post(
    `project/public/api/purchase-order/updatestatus/${id}`,
    body
  );
};

const GetAllPurchaseOrderByvendor = (id) => {
  return axios.get(`project/public/api/purchase-order/getByVendorId/${id}`);
};

const CreateEstablishmentItemCategory = (body) => {
  return axios.post(
    "project/public/api/budget/esatblishment-to-item-category-mapping/create",
    body
  );
};
const CreateItemCategorySubCategory = (body) => {
  return axios.post(
    "project/public/api/budget/item-category-subcategory-mapping/create",
    body
  );
};
const GetActiveitemCategory = () => {
  return axios.get("project/public/api/master/item-category/getAllActive");
};
const GetAllActiveItemSubCategories = () => {
  return axios.get("project/public/api/master/item-sub-category/getAllActive");
};

const GetAllEstablishmentItemCategory = (row, page, search) => {
  return axios.get(
    `project/public/api/budget/esatblishment-to-item-category-mapping/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetAllItemCategorySubCategoryMap = (row, page, search) => {
  return axios.get(
    `project/public/api/budget/item-category-subcategory-mapping/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetItemSubCategoryById = (id) => {
  return axios.get(
    `project/public/api/budget/item-category-subcategory-mapping/get/${id}`
  );
};
const GetItemCategoryById = (id) => {
  return axios.get(
    `project/public/api/budget/esatblishment-to-item-category-mapping/get/${id}`
  );
};

const GetEstablishmentMap = () => {
  return axios.get(
    "project/public/api/budget/esatblishment-to-item-category-mapping/getAll"
  );
};

const GetSubCategoryById = (id) => {
  return axios.get(
    `project/public/api/budget/item-category-subcategory-mapping/get/${id}`
  );
};

const UpdateEstablishmentItemCategory = (body) => {
  return axios.post(
    "project/public/api/budget/esatblishment-to-item-category-mapping/update",
    body
  );
};

const UpdateItemCategorySubCategory = (body) => {
  return axios.post(
    "project/public/api/budget/item-category-subcategory-mapping/update",
    body
  );
};

const UpdateRfi = (body, id) => {
  return axios.post(
    `project/public/api/quotation/request-for-information/update/${id}`,
    body
  );
};

const GetRfi = (id) => {
  return axios.get(
    `project/public/api/quotation/request-for-information/get/${id}`
  );
};

const GetRfq = (id) => {
  return axios.get(
    `project/public/api/quotation/request-for-quotation/get/${id}`
  );
};

const UpdateRFQ = (body, id) => {
  return axios.post(
    `project/public/api/quotation/request-for-quotation/update/${id}`,
    body
  );
};
const CreateQuotation = (body) => {
  return axios.post("project/public/api/quotation/create", body);
};

const UpdateQuotation = (body, id) => {
  return axios.post(`project/public/api/quotation/update/${id}`, body);
};

const GetAllQuotation = (id) => {
  return axios.get(`project/public/api/quotation/getAll/${id}`);
};

const GetAllVendorQuotation = (body, rowsPerPage, page) => {
  return axios.post(
    `project/public/api/quotation/getAllByVendor?perpage=${rowsPerPage}&page=${page}`,
    body
  );
};

const DeleteQuotation = (id) => {
  return axios.post(`project/public/api/quotation/deactivate/${id}`, {});
};
const UploadQuotation = (body) => {
  return axios.post(`project/public/api/quotation/createCsv`, body);
};
const DeleteRequestForInformation = (id) => {
  return axios.post(
    `project/public/api/quotation/request-for-information/changeStatus/${id}`,
    {}
  );
};

const GetAllInvoice = () => {
  return axios.get("project/public/api/invoice/getAll");
};
const GetAllActiveInvoice = (id, rowsPerPage, page) => {
  return axios.get(
    `project/public/api/invoice/getAllActive/${id}?perpage=${rowsPerPage}&page=${page}`
  );
};
const GetAllInvoiceById = (id) => {
  return axios.get(`project/public/api/invoice/getAllInvoiceForPaymentRequest/${id}`);
};
const GetPaymentHistoryBYInvoiceById = (po,id) => {
  return axios.get(`project/public/api/invoice/invoiceHistory/${po}/${id}`);
};
const GetAllPaymentSummeryById = (id) => {
  return axios.get(`project/public/api/invoice-payment-summary/getAll/${id}`);
};
const GetAllActivePaymentSummeryById = (id) => {
  return axios.get(`project/public/api/invoice/getAllInvoiceForPaymentRequest/${id}`);
};
const CreatePaymentRequestSummery = (body) => {
  return axios.post(`project/public/api/invoice-payment-summary/create`, body);
};

const CreateInvoice = (body) => {
  return axios.post("project/public/api/invoice/create", body);
};
const UpdateInvoice = (id, body) => {
  return axios.post(`project/public/api/invoice/update/${id}`, body);
};
const ChangeInvoiceStatus = (id) => {
  return axios.post(`project/public/api/invoice/changeStatus/${id}`, {});
};

const AllActiveStatusLocation = () => {
  return axios.get(`project/public/api/master/location/getAllActive`);
};

const GetAllPurchaseOrder = () => {
  return axios.get("project/public/api/purchase-order/getAll");
};

const GetAllPurchaseOrderDataByQid = (id) => {
  return axios.get(`project/public/api/purchase-order/getByRfqId/${id}`);
};

const GetAllPOByBudget = (id) => {
  return axios.get(`project/public/api/budget/budget-item/getAllPO/${id}`);
};

const GetAllPOByBudgetByClient = (id) => {
  return axios.get(`project/public/api/budget/budget-item/getAllPOForClient/${id}`);
};

const GetPurchaseOrderById = (id) => {
  return axios.get(`project/public/api/purchase-order/get/${id}`);
};

const GetAllItemSubGroup = (row, page, search) => {
  return axios.get(
    `project/public/api/master/subgroup/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const GetAllActiveItemSubGroup = () => {
  return axios.get("project/public/api/master/subgroup/getAllActive");
};

const CreateItemSubgroup = (body) => {
  return axios.post("project/public/api/master/subgroup/create", body);
};
const CreatePaymentInvoice = (body) => {
  return axios.post("project/public/api/invoice-payment/create", body);
};

const UpdateItemSubActiveStatus = (body, id) => {
  return axios.post(
    `project/public/api/master/subgroup/changeStatus/${id}`,
    body
  );
};
const UpdateItemSubGroup = (body, id) => {
  return axios.post(`project/public/api/master/subgroup/update/${id}`, body);
};
const GetCostAnalysis = (id) => {
  return axios.get(`project/public/api/cost-analysis/getAll/${id}`);
};
const CreateCostAnalysis = (body) => {
  return axios.post("project/public/api/cost-analysis/create", body);
};
const GetAllVendorCost = (body) => {
  return axios.post(`project/public/api/cost-analysis/get`, body);
};

const GetSelectItemsByRfqId = (id) => {
  return axios.get(
    `project/public/api/cost-analysis/getAllVendorQuotation/${id}`
  );
};
const GetRevisionSelectItemByRfqId = (id) => {
  return axios.get(
    `project/public/api/cost-analysis/getAllVendorQuotationRevision/${id}`
  );
};

const GetPaymentByInvoiceId = (id, row, page) => {
  return axios.get(
    `project/public/api/invoice-payment/getByInvoiceId/${id}?perpage=${row}&page=${page}`
  );
};

const DeleteInvoicePayment = (id) => {
  return axios.post(`project/public/api/invoice-payment/changeStatus/${id}`);
};

const GetAssetByBudgetId = (id) => {
  return axios.get(`project/public/api/purchase-order/getAllActiveAsset/${id}`);
};
const GetBudgetItemPrice = (id) => {
  return axios.get(`project/public/api/cost-analysis/getBudgetItemPrice/${id}`);
};
const GetAllRole = (row, page, search) => {
  return axios.get(
    `authservice/public/api/role?perpage=${row}&page=${page}&search=${search}`
  );
};
const UpdateRoleStatuse = (id, body) => {
  return axios.post(`authservice/public/api/role/changeStatus/${id}`, body);
};
const UpdateUserRole = (id, body) => {
  return axios.put(`authservice/public/api/role/${id}`, body);
};
const CreateUserRole = (body) => {
  return axios.post("authservice/public/api/role", body);
};
const GetAllFunctionManagement = (row, page, search) => {
  return axios.get(
    `authservice/public/api/functionality?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateFunctionManagement = (body) => {
  return axios.post("authservice/public/api/functionality", body);
};
const UpdateFunctionManagement = (id, body) => {
  return axios.put(`/authservice/public/api/functionality/${id}`, body);
};
const UpdateStatusFunctionManagement = (id, body) => {
  return axios.post(
    `authservice/public/api/functionality/changeStatus/${id}`,
    body
  );
};
const GetAllActiveModules = () => {
  return axios.get("authservice/public/api/module/active");
};
const UpdateModule = (id, body) => {
  return axios.put(`authservice/public/api/module/${id}`, body);
};
const ChangeModuleStatus = (id, body) => {
  return axios.post(`authservice/public/api/module/changeStatus/${id}`, body);
};
const CreateProjectRole = (body) => {
  return axios.post("/project/public/api/project-role/create", body);
};
const UpdateProjectRole = (id, body) => {
  return axios.post(`/project/public/api/project-role/update/${id}`, body);
};
const GetAllProjectRole = (row, page, search) => {
  return axios.get(
    `project/public/api/project-role/getAll?perpage=${row}&page=${page}&search=${search}`
  );
};
const UpdateStatusProjectRole = (id, body) => {
  return axios.post(`project/public/api/project-role/changeStatus/${id}`, body);
};
const GetModules = (row, page, search) => {
  return axios.get(
    `authservice/public/api/module?perpage=${row}&page=${page}&search=${search}`
  );
};
const ModuleCreate = (body) => {
  return axios.post("authservice/public/api/module", body);
};
const GetAllActiveQuotation = (id, row, page) => {
  return axios.get(
    `project/public/api/quotation/getAllActive/${id}?perpage=${row}&page=${page}`
  );
};
const CreateUserRoleMap = (body) => {
  return axios.post(`authservice/public/api/identity-role-mapping`, body);
};
const GetUserRoleMap = (row, page, search) => {
  return axios.get(
    `authservice/public/api/identity-role-mapping?perpage=${row}&page=${page}&search=${search}`
  );
};
const UpdateUserRoleMapping = (body) => {
  return axios.post(
    `authservice/public/api/identity-role-mapping/update`,
    body
  );
};
const GetActiveFunctionality = () => {
  return axios.get("authservice/public/api/functionality/active");
};
const GetActiveIdentityUser = () => {
  return axios.get("authservice/public/api/identity/active");
};
const GetActiveRole = () => {
  return axios.get("authservice/public/api/role/active");
};
const GetUserRoleMapById = (id) => {
  return axios.get(`authservice/public/api/identity-role-mapping/${id}`);
};
const GetIdentityRoleMapping = (id) => {
  return axios.get(`authservice/public/api/identity-role-mapping/${id}`);
};
const GetRoleFunctionality = (row, page, search) => {
  return axios.get(
    `authservice/public/api/role-functionality-mapping?perpage=${row}&page=${page}&search=${search}`
  );
};
const CreateRoleFunctionality = (body) => {
  return axios.post("authservice/public/api/role-functionality-mapping", body);
};
const UpdateRoleFunctionality = (body) => {
  return axios.post(
    "authservice/public/api/role-functionality-mapping/update",
    body
  );
};
const GetAuthorization = (token) => {
  return axios.post(
    "authservice/public/api/userauthorization",
    {},
    {
      headers: {
        "X-API-KEY": 1234,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
};
const BudgetFileUpload = (body) => {
  return axios.post("project/public/api/budget/createfile", body);
};
const BudgetItemFileUpload = (body) => {
  return axios.post("project/public/api/budget/budget-item/createfile", body);
};
const PurchaseOrderFileUploads = (id, body) => {
  return axios.post(
    `/project/public/api/purchase-order/createfile/${id}`,
    body
  );
};
const InvoiceFileUploads = (body) => {
  return axios.post(`project/public/api/invoice/createfile`, body);
};

const ClientSignatureUploads = (body) => {
  return axios.post(`project/public/api/invoice/createfile`, body);
};

const GetActiveOperator = () => {
  return axios.get("project/public/api/master/operator/getAllActive");
};
const UpdateProjectStatus = (id, body) => {
  return axios.post(`project/public/api/project/updatestatus/${id}`, body);
};
const getProjectCode = (project) => {
  return axios.get(`project/public/api/getcode/${project}`);
};
const UploadBudgetItem = (body) => {
  return axios.post(`project/public/api/budget/budget-item/createCsv`, body);
};
const BudgetItemImageUpload = (id, body) => {
  return axios.post(
    `project/public/api/budget/budget-item/uploadImage/${id}`,
    body
  );
};
const getBudgetCode = (budget) => {
  return axios.get(`project/public/api/getcode/${budget}`);
};
const GetActiveProjectRole = () => {
  return axios.get("project/public/api/project-role/getAllActive");
};
const GetProjectSpecificUser = (id) => {
  return axios.get(`project/public/api/project-specific-user/project/${id}`);
};
const CreateProjectSpecificUser = (body) => {
  return axios.post("project/public/api/project-specific-user/create", body);
};
const UpdateProjectSpecificUser = (id, body) => {
  return axios.post(
    `project/public/api/project-specific-user/update/${id}`,
    body
  );
};
const DeleteProjectSpecificUser = (id) => {
  return axios.post(
    `project/public/api/project-specific-user/delete/${id}`,
    {}
  );
};
const GetAllDraftItems = (id) => {
  return axios.get(`project/public/api/budget/budget-item-draft/getAll/${id}`);
};
const GetAllActiveBudgetItems = (id) => {
  return axios.get(
    `/project/public/api/budget/budget-item-draft/getAllActive/${id}`
  );
};
const CreateDraftItems = (body) => {
  return axios.post("project/public/api/budget/budget-item-draft/create", body);
};
const getRfiCode = (project) => {
  return axios.get(`/project/public/api/getcode/${project}`);
};
const DeleteDraftItem = (id) => {
  return axios.post(
    `project/public/api/budget/budget-item-draft/changeStatus/${id}`
  );
};

const UpdateRFIStatus = (id, body) => {
  return axios.post(
    `project/public/api/quotation/request-for-information/updatestatus/${id}`,
    body
  );
};

const GetCostAnalysisReport = (id) => {
  return axios.get(
    `project/public/api/cost-analysis/getCostAnalysisById/${id}`
  );
};

const createPODraft = (body) => {
  return axios.post(`project/public/api/purchase-order-draft/create`, body);
};

const GetAllActivePO = () => {
  return axios.get(`/project/public/api/purchase-order-draft/getAllActive`);
};
const GetAllEditPO = (id) => {
  return axios.post(`/project/public/api/purchase-order-draft/update/${id}`);
};
const GetAllDeletePO = (id) => {
  return axios.post(
    `/project/public/api/purchase-order-draft/changeStatus/${id}`
  );
};

const GetPOHistory = (id) => {
  return axios.get(`project/public/api/purchase-order/getRevisionById/${id}`);
};
const GetPriceByPurchaseOrder = (id,budgetId) => {
  return axios.get(`project/public/api/invoice/getPriceByPurchaseOrder/${id}/${budgetId}`);
};

const GetProjectStatusReport = () => {
  return axios.get("/project/public/api/project/getAllStatus");
};
const GetVendorReport = () => {
  return axios.get("project/public/api/master/vendor/getAllvendorcount");
};
const GetClientReport = () => {
  return axios.post("project/public/api/master/client/getAll");
};
const GetBudgetScopeReport = () => {
  return axios.get("/project/public/api/project/getAllProjectScopeCount");
};
const UpdateItemQtyCostAnalysis = (id, body) => {
  return axios.post(
    `project/public/api/budget/budget-item/updateCostanalisys/${id}`,
    body
  );
};
const GetAllPurchaseItem = (id) => {
  return axios.get(`project/public/api/deliverynotes/getAll/${id}`);
};
const CreateDelivery = (body) => {
  return axios.post(`project/public/api/deliverynotes/create`, body);
};

const GetAllDeliveryNotesByPO = (id) => {
  return axios.get(
    `project/public/api/deliverynotes/getAllDeliveryNotes/${id}`
  );
};
const GetPaymentReport = () => {
  return axios.get("project/public/api/master/client/getAllClientStatus");
};
const GetDeliveryQtyByPo = (body) => {
  return axios.post("project/public/api/deliverynotes/getallprice", body);
};

const GetPurchaseOrderFileData = () => {
  return axios.get(`project/public/api/purchase-order/getAllFiles`);
};
const GetInvoiceFileData = () => {
  return axios.get(`project/public/api/invoice/getAllFile`);
};
const GetUserNameByAuthId = (id) => {
  return axios.get(`project/public/api/master/user/getauthdata/${id}`);
};
const GetBudgetFile = (id) => {
  return axios.get(`project/public/api/budget/getallfile/${id}`);
};
const GetBudgetItemFile = () => {
  return axios.get(`project/public/api/budget/budget-item/getallfile`);
};
const GetPurchaseOrderData = () => {
  return axios.get(`project/public/api/purchase-order/getAll`);
};
const GetPurchaseOrderDetails = () => {
  return axios.get(`project/public/api/purchase-order/getAllFiles`);
};
const GetInvoiceOrderDetails = () => {
  return axios.get(`project/public/api/invoice/getAllFile`);
};
const ChangeStatus = (id) => {
  return axios.get(
    `project/public/api/quotation/request-for-information/changeStatus/${id}`
  );
};
const GetAllClientStatus = () => {
  return axios.get("project/public/api/master/client/getAllClientStatus");
};
const GetDynamicReport = (id) => {
  return axios.get(`project/public/api/budget/report/summaryreport/${id}`);
};
const GetvarianceReport = (id) => {
  return axios.get(`project/public/api/budget/report/varianceReport/${id}`);
};
const GetVendorDataReport = (id) => {
  return axios.get(`project/public/api/budget/report/vendorTablereport/${id}`);
};
const GetPurchaseSummeryReport = (id) => {
  return axios.get(`project/public/api/budget/report/posummaryreport/${id}`);
};
const GetDeliveryReport = (id) => {
  return axios.get(`project/public/api/budget/report/deliveryreport/${id}`);
};

const GetStatementReport = (id) => {
  return axios.get(
    `project/public/api/budget/report/accountstatementreport/${id}`
  );
};
const GetAccountStatementReport = (id) => {
  return axios.get(
    `project/public/api/budget/report/allaccountstmentreport/${id}`
  );
};
const GetManagementReport = (id) => {
  return axios.get(`project/public/api/budget/report/managementreport/${id}`);
};
const GetVendorRFQById = (id) => {
  return axios.get(
    `project/public/api/quotation/request-for-quotation/get/${id}`
  );
};
const GetRfiByRfq = (id) => {
  return axios.get(
    `project/public/api/quotation/request-for-information/getByrfq/${id}`
  );
};
const GetPOStatusByVendor = (id) => {
  return axios.get(`project/public/api/purchase-order/getByVendorWise/${id}`);
};
const CreateRfiByVendor = (body) => {
  return axios.post(
    "project/public/api/quotation/request-for-information/createVendorRfi",
    body
  );
};

const GetAllBudgetScopeToGroupMap = (page, row, search) => {
  return axios.get(
    `project/public/api/master/budget-scope-item-group-mapping/getAll?perpage=${page}&page=${row}&search=${search}`
  );
};

const GetItemGroupByScopeId = (id) => {
  return axios.get(
    `project/public/api/master/budget-scope-item-group-mapping/get/${id}`
  );
};

const CreateBudgetScopeToGroupMap = (body) => {
  return axios.post(
    "project/public/api/master/budget-scope-item-group-mapping/create",
    body
  );
};

const UpdateBudgetScopeToGroupMap = (body) => {
  return axios.post(
    "project/public/api/master/budget-scope-item-group-mapping/update",
    body
  );
};

const GetAllGroupToSubGroupMap = (page, row, search) => {
  return axios.get(
    `project/public/api/master/item-group-subgroup-mapping/getAll?perpage=${page}&page=${row}&search=${search}`
  );
};

const CreateGroupToSubGroupMap = (body) => {
  return axios.post(
    "project/public/api/master/item-group-subgroup-mapping/create",
    body
  );
};

const UpdateGroupToSubGroupMap = (body) => {
  return axios.post(
    "project/public/api/master/item-group-subgroup-mapping/update",
    body
  );
};

const GetAllSubGroupByGroup = (id) => {
  return axios.get(
    `project/public/api/master/item-group-subgroup-mapping/get/${id}`
  );
};
const GetAllSubGroupByGroupByScopeID = (body) => {
  return axios.post(
    `project/public/api/master/item-group-subgroup-mapping/getby_scopeid_groupid`,
    body
  );
};
const ChangeVendorLoginStatus = (id) => {
  return axios.post(
    `project/public/api/master/vendor/changeStatusloginAccess/${id}`
  );
};

const GetGroupReport = (id) => {
  return axios.get(`project/public/api/budget/budget-item/getReport/${id}`);
};

const GetCategoryReport = (id,row, page, search) => {
  
  return axios.get(`project/public/api/master/budget-catagory-mapping/getAllActive/${id}?perpage=${row}&page=${page}&search=${search}`);
};

const UpdateCategoryReport = (id) => {
  return axios.post(`project/public/api/master/budget-catagory-mapping/status-change/${id}`,{'status':false});
};

const addCategoryInBudgetItem = (body) => {
  return axios.post(`project/public/api/master/budget-catagory-mapping/store`,body);
}

const GetPaymentSummery = (id) => {
  return axios.get(
    `project/public/api/budget/budget-item/getAllPaymentSummaryReport/${id}`
  );
};

const GetBudgetItemByID = (id) => {
  return axios.get(`project/public/api/budget/budget-item/get/${id}`);
};
const GetBudgetItemCategoryByID = (id) => {
  return axios.get(
    `project/public/api/master/budget-scope-item-category-mapping/get/${id}`
  );
};
const GetBudgetByID = (id) => {
  return axios.get(`project/public/api/budget/get/${id}`);
};
const GetAllItemsByVendor = (body, id) => {
  return axios.post(
    `project/public/api/budget/budget-item/getByVendorId/${id}`,
    body
  );
};

const GetReconcilationReport = (id) => {
  return axios.get(`/project/public/api/deliverynotes/getReconciliation/${id}`);
};

const GetBudgetReports = (id) => {
  return axios.get(`project/public/api/budget/report/budgetReport/${id}`);
};

const GetBudgetCategory = (id,row, page, search) => {
  
  return axios.get(`project/public/api/master/budget-catagory-mapping/getAllActiveForDropDown/${id}`);
};

const GetBudgetSubGroupByGroup = (budget_id,group_id,row, page, search) => {
  
  return axios.get(`project/public/api/master/budget-group-subgroup-mapping/getAllActiveForDropDown/${budget_id}/${group_id}`);
};

const UpdateBudgetGroupSubgroup = (id) => {
  return axios.post(`project/public/api/master/budget-group-subgroup-mapping/status-change/${id}`,{'status':false});
};

const addBudgetGroupSubgroup = (body) => {
  return axios.post(`project/public/api/master/budget-group-subgroup-mapping/store`,body);
}

const GetBudgetGroupSubGroup = (id,row, page, search) => {
  
  return axios.get(`project/public/api/master/budget-group-subgroup-mapping/getAllActive/${id}?perpage=${row}&page=${page}&search=${search}`);
};

const UpdateDeliveryNote = (body,id) => {
  return axios.post(`project/public/api/deliverynotes/update/${id}`,body);
};
const DeleteDeliveryNote = (id) => {
  return axios.post(`project/public/api/deliverynotes/changeStatus/${id}`);
};

const GetClientSpecificUser = (clientid) => {
  return axios.get(`project/public/api/master/clientContact/getAllActiveByClient/${clientid}`);
}

const GetAllActiveProjectById = (row, page,userId) => {
  return axios.get(
    `project/public/api/project/getAllActiveForSpecificUser/${userId}?perpage=${row}&page=${page}`
  );
};
const GetAllCompletedProjectById = (row, page,userId) => {
  return axios.get(
    `project/public/api/project/getAllCompletedForSpecificUser/${userId}?perpage=${row}&page=${page}`
  );
};
const GetAllDeactivatedProjectById = (row, page,userId) => {
  return axios.get(
    `project/public/api/project/getAllDeactiveForSpecificUser/${userId}?perpage=${row}&page=${page}`
  );
};
const GetAllOngoingProjectById = (row, page,userId) => {
  return axios.get(
    `project/public/api/project/getAllOngoingForSpecificUser/${userId}?perpage=${row}&page=${page}`
  );
};

const GetControlNumber = () => {
  return axios.get(
    `project/public/api/budget/budget-item/getControlNumber`
  )
}

export {
  AllActiveStatusCompanyType,
  AllActiveStatusCurrency,
  AllActiveStatusItemGroup,
  AllActiveStatusLocation,
  AllActiveStatusModeOfPayment,
  AllActiveStatusOperator,
  AllActiveStatusPublicArea,
  AllActiveStatusRequiredDocument,
  AllActiveStatusRequiredSubmittals,
  AllActiveStatusRoomType,
  //
  AllActiveStatusUserType,
  AllCompleteProjects,
  AllDeactiveProject,
  AllOngoingProject,
  BudgetFileUpload,
  BudgetItemFileUpload,
  BudgetItemImageUpload,
  ChangeActiveStatusBudgetSchedule,
  ChangeActiveStatusProject,
  ChangeActiveStatusProjectBudget,
  ChangeBranchStatus,
  ChangeInvoiceStatus,
  ChangeModuleStatus,
  ChangePassword,
  ChangeRFQStatus,
  ChangeStatus,
  ChangeVendorLoginStatus,
  ChangeVendorStatus,
  ConfirmForgetPassword,
  CreateAccessedConsideration,
  CreateAllVendorCategorySubCategory,
  CreateBranch,
  CreateBudget,
  CreateBudgetItem,
  CreateBudgetSchedule,
  CreateBudgetScopeItemCategoryMap,
  CreateBudgetScopeToGroupMap,
  CreateClient,
  CreateClientContact,
  CreateClientManagement,
  CreateClientType,
  CreateCostAnalysis,
  CreateCountryType,
  //
  CreateCurrency,
  CreateDelivery,
  CreateDraftItems,
  CreateEstablishment,
  CreateEstablishmentItemCategory,
  //
  CreateFinalPaymentAgainst,
  CreateFunctionManagement,
  CreateGroupToSubGroupMap,
  CreateIncoterm,
  CreateInvoice,
  CreateItemCategorySubCategory,
  //
  CreateItemGroup,
  CreateItemSchedule,
  CreateItemSubgroup,
  CreateItemSubmittals,
  //
  CreateLocation,
  CreateModeOfPayment,
  CreateModeOfShipment,
  CreateOperator,
  CreatePaymentInvoice,
  CreatePaymentProgress,
  CreatePaymentRequestSummery,
  CreatePaymentTerms,
  CreateProductServices,
  CreateProject,
  CreateProjectBudget,
  CreateProjectRole,
  CreateProjectScope,
  CreateProjectSpecificUser,
  CreatePublicArea,
  CreatePurchaseOrder,
  CreateQuotation,
  CreateRFI,
  CreateRFQ,
  CreateRequiredDocument,
  CreateRequiredSubmittals,
  CreateRfiByVendor,
  CreateRoleFunctionality,
  //
  CreateRoomType,
  CreateSchedule,
  CreateStage,
  CreateSubmittals,
  CreateTransportationMode,
  CreateUOM,
  CreateUserManagement,
  CreateUserRole,
  CreateUserRoleMap,
  CreateUserType,
  CreateVendor,
  CreateVendorCategory,
  CreateVendorCompanyType,
  CreateVendorContact,
  CreateVendorSubCategory,
  Createcommodity,
  CreateitemCategory,
  CreateitemsubCategory,
  CreatetCategorySubProductMapping,
  DeleteBudgetItem,
  DeleteDraftItem,
  DeleteInvoicePayment,
  DeleteItemSchedule,
  DeleteProjectSpecificUser,
  DeleteQuotation,
  DeleteRequestForInformation,
  ForgetPassword,
  //
  GetAccessedConsideration,
  GetAccountStatementReport,
  GetActiveFinalPaymentAgainst,
  GetActiveFunctionality,
  GetActiveIdentityUser,
  GetActiveOperator,
  GetActivePaymentProgress,
  GetActiveProjectRole,
  GetActiveRequiredSubmittals,
  GetActiveRole,
  GetActiveitemCategory,
  GetAllActiveBranches,
  GetAllActiveBudgetItems,
  GetAllActiveBudgetSchedule,
  GetAllActiveClient,
  GetAllActiveClientContact,
  GetAllActiveClientType,
  GetAllActiveCommodities,
  GetAllActiveCountry,
  GetAllActiveEstablishment,
  GetAllActiveIncoterm,
  GetAllActiveInvoice,
  GetAllActiveItemCategory,
  GetAllActiveItemSubCategories,
  GetAllActiveItemSubGroup,
  GetAllActiveModules,
  GetAllActivePO,
  GetAllActivePaymentTerms,
  GetAllActiveProducts,
  GetAllActiveProject,
  GetAllActiveProjectBudget,
  GetAllActiveProjectScope,
  GetAllActivePurchaseOrder,
  GetAllActiveQuotation,
  GetAllActiveRequiredDocument,
  GetAllActiveRfi,
  GetAllActiveRfq,
  GetAllActiveStage,
  GetAllActiveTransportation,
  GetAllActiveUOM,
  GetAllActiveUserManagement,
  GetAllActiveVendor,
  GetAllActiveVendorCategory,
  GetAllActiveVendorSubCategory,
  GetAllBranches,
  GetAllBudgetFilteItemById,
  GetAllBudgetItemById,
  GetAllBudgetScope,
  //
  GetAllBudgetScopeItemCategoryMap,
  GetAllBudgetScopeToGroupMap,
  //
  GetAllCategorySubProductMapping,
  //
  GetAllClient,
  GetAllClientBySearch,
  GetAllClientContactsById,
  GetAllClientPagination,
  GetAllClientStatus,
  GetAllClientType,
  GetAllCountryList,
  GetAllDeactiveProject,
  GetAllDeletePO,
  GetAllDeliveryNotesByPO,
  GetAllDraftItems,
  GetAllDraftProject,
  GetAllDrafts,
  GetAllEditPO,
  GetAllEstablishmentItemCategory,
  GetAllFunctionManagement,
  GetAllGroupToSubGroupMap,
  GetAllHistoryRFQById,
  GetAllInvoice,
  GetAllInvoiceById,
  GetAllItemCategorySubCategoryMap,
  GetAllItemSubGroup,
  GetAllItemSubmittals,
  GetAllItemsByVendor,
  GetAllOperator,
  GetAllPOByBudget,
  GetAllPaymentSummeryById,
  GetAllProject,
  GetAllProjectRole,
  GetAllProjectStatusByVendor,
  GetAllProjects,
  GetAllPurchaseItem,
  GetAllPurchaseOrder,
  GetAllPurchaseOrderByvendor,
  GetAllPurchaseOrderDataByQid,
  GetAllQuotation,
  GetAllRFI,
  GetAllRFQ,
  GetAllRFQByVendor,
  GetAllRFQVendor,
  GetAllRole,
  GetAllRoomType,
  GetAllSchedule,
  GetAllStageActiveStatus,
  GetAllSubGroupByGroup,
  GetAllSubGroupByGroupByScopeID,
  //
  GetAllUOM,
  //
  GetAllUserType,
  GetAllVendor,
  //
  GetAllVendorCategorySubCategory,
  GetAllVendorCompanyType,
  GetAllVendorCost,
  GetAllVendorQuotation,
  GetAssetByBudgetId,
  GetAuthorization,
  GetBudget,
  GetBudgetByID,
  GetBudgetFile,
  GetBudgetItem,
  GetBudgetItemByID,
  GetBudgetItemById,
  GetBudgetItemCategoryByID,
  GetBudgetItemFile,
  GetBudgetItemPrice,
  //
  GetBudgetReport,
  GetBudgetReports,
  GetBudgetSchedule,
  GetBudgetScheduleById,
  GetBudgetScopeReport,
  GetClientContact,
  //
  GetClientManagement,
  GetClientReport,
  GetCompleteProject,
  GetCostAnalysis,
  GetCostAnalysisReport,
  //
  GetCountryType,
  GetCurrency,
  GetDeliveryQtyByPo,
  GetDeliveryReport,
  GetDynamicReport,
  //
  GetEstablishment,
  GetEstablishmentMap,
  GetFinalPaymentAgainst,
  GetGroupReport,
  GetCategoryReport,
  UpdateCategoryReport,
  addCategoryInBudgetItem,
  GetIdentityRoleMapping,
  //
  GetIncoterm,
  GetInvoiceFileData,
  GetInvoiceOrderDetails,
  GetItemCategoryByBudgetScope,
  GetItemCategoryById,
  GetItemGroup,
  GetItemGroupByScopeId,
  GetItemReport,
  GetItemSchedule,
  GetItemSubCategoryById,
  GetLocation,
  GetManagementReport,
  //
  GetModeOfPayment,
  //
  GetModeOfShipment,
  GetModules,
  GetOngoingProject,
  GetPOHistory,
  GetPOStatusByVendor,
  GetPaymentByInvoiceId,
  //
  GetPaymentProgress,
  GetPaymentReport,
  GetPaymentSummery,
  //
  GetPaymentTerms,
  GetPriceByPurchaseOrder,
  //
  GetProductServices,
  GetProjectBudget,
  GetProjectById,
  //
  GetProjectScope,
  GetProjectSpecificUser,
  GetProjectStatusReport,
  //
  GetPublicArea,
  GetPurchaseOrderById,
  GetPurchaseOrderData,
  GetPurchaseOrderDetails,
  GetPurchaseOrderFileData,
  GetPurchaseSummeryReport,
  GetRFQById,
  GetReconcilationReport,
  GetReportData,
  //
  GetRequiredDocument,
  //
  GetRequiredSubmittals,
  GetRevisionSelectItemByRfqId,
  GetRfi,
  GetRfiByRfq,
  GetRfq,
  GetRoleFunctionality,
  GetSelectItemsByRfqId,
  //
  GetStage,
  GetStatementReport,
  GetSubCategoryById,
  //
  GetSubmittals,
  GetTotalVendors,
  //
  GetTransportationMode,
  GetUserManagement,
  GetUserManagementById,
  GetUserManagementBySearch,
  GetUserNameByAuthId,
  GetUserProfile,
  GetUserRoleMap,
  GetUserRoleMapById,
  GetUserTypeDropdown,
  GetUsers,
  GetVendorByEmail,
  GetVendorById,
  //
  GetVendorCategory,
  GetVendorCompanyList,
  //
  GetVendorCompanyType,
  GetVendorContact,
  GetVendorDataReport,
  GetVendorRFQById,
  GetVendorReport,
  //
  GetVendorSubcategory,
  //
  Getcommodity,
  //
  GetitemCategory,
  GetitemsubCategory,
  GetvarianceReport,
  ImageCreation,
  InvoiceFileUploads,
  Login,
  Logout,
  ModuleCreate,
  PurchaseOrderFileUploads,
  StatusChangeVendorContact,
  UpdateAccessedConsiderationActiveStatus,
  UpdateActiveStatus,
  UpdateBranch,
  UpdateBudget,
  UpdateBudgetItem,
  UpdateBudgetSchedule,
  UpdateBudgetScopeItemCategoryMap,
  UpdateBudgetScopeToGroupMap,
  UpdateBudgetStatus,
  UpdateClient,
  UpdateClientContactActiveStatus,
  UpdateClientManagement,
  UpdateClientManagementStatus,
  UpdateClientStatus,
  UpdateClientType,
  UpdateClientTypeStatus,
  UpdateCommentDeliveryDate,
  UpdateCountryTypeActiveStatus,
  UpdateCurrencyActiveStatus,
  UpdateEstablishment,
  UpdateEstablishmentItemCategory,
  UpdateEstablishmentStatus,
  UpdateFinalPaymentAgainstActiveStatus,
  UpdateFunctionManagement,
  UpdateGroupToSubGroupMap,
  UpdateIncotermActiveStatus,
  UpdateInvoice,
  UpdateItemCategorySubCategory,
  UpdateItemGroupActiveStatus,
  UpdateItemQtyCostAnalysis,
  UpdateItemSchedule,
  UpdateItemSubActiveStatus,
  UpdateItemSubGroup,
  UpdateItemSubmittals,
  UpdateLocationActiveStatus,
  UpdateModeOfPaymentActiveStatus,
  UpdateModeOfShipment,
  UpdateModeOfShipmentStatus,
  UpdateModule,
  UpdateOperator,
  UpdateOperatorActiveStatus,
  UpdateOrderStatus,
  UpdatePaymentProgressActiveStatus,
  UpdatePaymentTermsActiveStatus,
  UpdateProductServicesActiveStatus,
  UpdateProfile,
  UpdateProject,
  UpdateProjectBudget,
  UpdateProjectRole,
  UpdateProjectScopeActiveStatus,
  UpdateProjectSpecificUser,
  UpdateProjectStatus,
  UpdatePublicAreaActiveStatus,
  UpdatePurchaseOrder,
  UpdatePurchaseOrderActiveStatus,
  UpdateQuotation,
  UpdateRFIStatus,
  UpdateRFQ,
  UpdateRequiredDocumentActiveStatus,
  UpdateRequiredSubmittalsActiveStatus,
  UpdateRfi,
  UpdateRoleFunctionality,
  UpdateRoleStatuse,
  UpdateRoomTypeActiveStatus,
  UpdateSchedule,
  UpdateScheduleStatus,
  UpdateStageActiveStatus,
  UpdateStatusFunctionManagement,
  UpdateStatusItemSubmittals,
  UpdateStatusProjectRole,
  UpdateSubmittals,
  UpdateSubmittalsStatus,
  UpdateTransportationModeActiveStatus,
  UpdateUOM,
  UpdateUOMStatus,
  UpdateUserManagementActiveStatus,
  UpdateUserProfile,
  UpdateUserRole,
  UpdateUserRoleMapping,
  UpdateUserType,
  UpdateVendorCategory,
  UpdateVendorCategoryStatus,
  UpdateVendorCategorySubCategory,
  UpdateVendorCompanyTypeActiveStatus,
  UpdateVendorContact,
  UpdateVendorManagement,
  UpdateVendorSubCategory,
  UpdateVendorSubCategoryStatus,
  UpdatecommodityActiveStatus,
  UpdatedAccessedConsideration,
  UpdatedClientContact,
  UpdatedCountryType,
  UpdatedCurrency,
  UpdatedFinalPaymentAgainst,
  UpdatedIncoterm,
  UpdatedInvoicePayment,
  UpdatedItemGroup,
  UpdatedLocation,
  UpdatedModeOfPayment,
  UpdatedPaymentProgress,
  UpdatedPaymentTerms,
  UpdatedProductServices,
  UpdatedProjectScope,
  UpdatedPublicArea,
  UpdatedRequiredDocument,
  UpdatedRequiredSubmittals,
  UpdatedRoomType,
  UpdatedStage,
  UpdatedTransportationMode,
  UpdatedUserManagement,
  UpdatedVendorCompanyType,
  Updatedcommodity,
  UpdateditemCategory,
  UpdateditemsubCategory,
  UpdateitemCategoryActiveStatus,
  UpdateitemsubCategoryActiveStatus,
  UpdatetCategorySubProductMapping,
  UploadBudgetItem,
  UploadClient,
  UploadQuotation,
  UploadVendor,
  createPODraft,
  getAllActiveVendor,
  getAllVendorBySearch,
  getBudgetCode,
  getProjectCode,
  getRfiCode,
  GetPaymentHistoryBYInvoiceById,
  getVendorByLineItem,
  GetBudgetCategory,
  GetBudgetSubGroupByGroup,
  UpdateBudgetGroupSubgroup,
  addBudgetGroupSubgroup,
  GetBudgetGroupSubGroup,
  DeleteDeliveryNote,
  UpdateDeliveryNote,
  GetAllActivePaymentSummeryById,
  GetClientSpecificUser,
  GetAllActiveProjectById,
  GetAllCompletedProjectById,
  GetAllDeactivatedProjectById,
  GetAllOngoingProjectById,
  GetAllPOByBudgetByClient,
  ClientSignatureUploads,
  GetProjectSpecificApprovers,
  GetProjectSpecificUsers,
  GetControlNumber
};
