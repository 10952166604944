/* eslint-disable no-unused-vars */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  ChangeBranchStatus,
  CreateBranch,
  GetAllBranches,
  UpdateBranch,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  Chip,
  IconButton,
  Modal,
  NodataFound,
  SwitchButton,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { VendorBranchForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import Strings from "../../../translations";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: false,
    label: Strings["VenderContactForm.BranchNameLabel"],
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: Strings["VenderContactForm.cityLabel"],
  },

  {
    id: "Phone Number",
    numeric: false,
    disablePadding: false,
    label: Strings["VenderManagement.TableHead.tel"],
  },

  {
    id: "Active Status",
    numeric: false,
    disablePadding: false,
    label: Strings["Status.TableHead.activeStatus"],
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label:  Strings["Status.TableHead.action"],
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function SearchVendorBranchPage() {
  const searchTitle = Strings["VendorContactPage.DashboardTitle"],
    // addButton = Strings["VendorContactPage.AddButton"],
    alertMessage = Strings["VendorContactPage.AlertMessage"],
    alertTitle = Strings["VendorContactPage.AlertTitle"];

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentVCategory, setCurrentCategory] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [statusCode, setStatusCode] = React.useState();
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  // const [currentStatus, setcurrentStatus] = React.useState(null);
  const paths = [
    {
      name: Strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: Strings["Navigation.SideBarVendor"],
      path: "master-configuration/vendor",
    },
    {
      name: Strings["VendorProfile"],
      path: "master-configuration/vendorprofile",
    },
    {
      name: Strings["Branches"],
      path: "vendorbranches/vendor.com",
    },
  ];

  const location = useLocation();
  const allData = location.state;

  //  modal works
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  React.useEffect(() => {
    getAllBranches();
  }, []);

  const vendorId = allData.id;

  const getAllBranches = () => {
    setisLoader(true);
    GetAllBranches(vendorId)
      .then((res) => {
        setRows(res.data.data);
        setisLoader(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  const createBranch = (formData) => {
    setisLoader(true);
    CreateBranch(formData)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows([res.data.data, ...rows]);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updateBranch = (formData) => {
    const id = currentVCategory.id;
    UpdateBranch(formData, id)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          getAllBranches();
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const selectModal = () => {
    return (
      <VendorBranchForm
        vendorId={vendorId}
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeFormData={
          clickedBtn === "add" ? createBranch : updateBranch
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentVCategory : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangeActiveStatus = (row) => {
    setStatusChangeId(row.id);
    setStatusCode(row.is_active);
    setAlertBox(true);
  };

  const handleChangeCancel = () => {
    setMessage(null);
    setAlertBox(false);
  };

  const handleChangeSuccess = () => {
    setisLoader(true);
    let code = {
      status: 1 - statusCode,
    };
    ChangeBranchStatus(statusChangeId, code)
      .then((res) => {
        setisLoader(false);
        setAlertBox(false);
        getAllBranches();
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  return (
    <div className="container">
      {isLoader ? <LoaderComponent /> : null}
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>

      <div
        className="row mt-3"
        style={{
          alignItems: "center",
        }}
      >
        <div className="col-5">
          <h3>{searchTitle}</h3>
          <BreadCrumbs pathList={paths} obj={allData} />
        </div>
        <div
          className="col-7 mt-3"
          style={{
            textAlign: "end",
            marginBottom: 20,
          }}
        >
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}
          <span className="addButton">
            <IconButton
              onClick={() => {
                openAddModal();
                handleSelectBtn("add");
              }}
            >
              <AddIcon />
            </IconButton>
          </span>
        </div>
        {rows.length > 0 ? (
          <>
            <Box
              sx={{ width: "100%", paddingBottom: "100px", marginTop: "20px" }}
            >
              <Paper sx={{ width: "100%", mb: 5 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.name);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox"></TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                // padding="none"
                                align="left"
                              >
                                {row.name.slice(0, 20).concat("...")}
                              </TableCell>
                              <TableCell align="left">{row.city}</TableCell>
                              <TableCell align="left">{row.phone}</TableCell>
                              <TableCell align="left">
                                <Chip
                                  label={
                                    row.is_active === 1 ? "Active" : "Inactive"
                                  }
                                  type={
                                    row.is_active === 1 ? "active" : "inactive"
                                  }
                                />
                              </TableCell>
                              <TableCell align="left">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => {
                                    openAddModal();
                                    handleSelectBtn("edit");
                                    setCurrentCategory(row);
                                  }}
                                >
                                  <Tooltip title="Edit">
                                    <EditIcon />
                                  </Tooltip>
                                </IconButton>
                                <SwitchButton
                                  checked={row.is_active}
                                  onClick={() => {
                                    handleChangeActiveStatus(row);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </>
        ) : (
          <>
            {isLoader ? (
              <LoaderComponent />
            ) : (
              <NodataFound
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SearchVendorBranchPage;
