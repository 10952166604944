import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { logoRect } from "../../../assets";
import { GetBudgetReports } from "../../../axios";

const BudgetReport = (props) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      // top: "0",
      right: "-500px",
      fontSize: "8px",
    },
  });

  const { id } = props;
  const [shows, setShows] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);
  const [budgetTotal, setBudgetTotal] = React.useState();
  const [forecastCostTotal, setForecastCostTotal] = React.useState();
  const [commitedTotal, setCommitedTotal] = React.useState();
  const [balanceToPurchaseTotal, setBalanceToPurchaseTotal] = React.useState();
  const [differenceTotal, setDifferenceTotal] = React.useState();

  React.useEffect(() => {
    GetBudgetReports(id)
      .then((res) => {
        setReportData(res.data.data);
        console.log(res.data.data);
        let totalBudget = res.data.data.group.map((ele) => ele.budgetTotal);
        const subgroupBudgets = res.data.data.group.map((ele) =>
          ele.subGroup.map((e) => e.budgetTotalSub)
        );
        const mergeTotal = totalBudget;
        const totalSubgroupBudget = mergeTotal.reduce(
          (acc, curr) => acc + curr,
          0
        );

        setBudgetTotal(totalSubgroupBudget);
        let forecastCostTotal = res.data.data.group.map(
          (ele) => ele.forecastCost
        );
        const forecastCostTotalsubgroupBudgets = res.data.data.group.map(
          (ele) => ele.forecastCost
        );
        const forecastCostTotalmergeTotal = forecastCostTotalsubgroupBudgets;
        const forecastCostTotaltotalSubgroupBudget =
          forecastCostTotalmergeTotal.reduce((acc, curr) => acc + curr, 0);

        setForecastCostTotal(forecastCostTotaltotalSubgroupBudget);
        let commitedTotal = res.data.data.group.map((ele) => ele.commited);
        const commitedTotalsubgroupBudgets = res.data.data.group.map((ele) =>
          ele.subGroup.map((e) => e.commitedSub)
        );
        const commitedTotalmergeTotal = commitedTotal;
        const commitedTotaltotalSubgroupBudget = commitedTotalmergeTotal.reduce(
          (acc, curr) => acc + curr,
          0
        );

        setCommitedTotal(commitedTotaltotalSubgroupBudget);
        let balanceToPurchase = res.data.data.group.map(
          (ele) => ele.balanceToPurchase
        );
        const subgroupbalanceToPurchase = res.data.data.group.map((ele) =>
          ele.subGroup.map((e) => e.balanceToPurchaseSub)
        );
        const balanceToPurchaseTotal = balanceToPurchase;
        const balanceToPurchaseTotals = balanceToPurchaseTotal.reduce(
          (acc, curr) => acc + curr,
          0
        );

        setBalanceToPurchaseTotal(balanceToPurchaseTotals);
        let differencePur = res.data.data.group.map((ele) => ele.difference);
        const subgroupdifference = res.data.data.group.map((ele) =>
          ele.subGroup.map((e) => e.differenceSub)
        );
        const differenceTotal = differencePur;
        const totalSubgroupdifference = differenceTotal.reduce(
          (acc, curr) => acc + curr,
          0
        );

        setDifferenceTotal(totalSubgroupdifference);
        setShows(true);
      })
      .catch((err) => {
        setShows(false);
      });
  }, [id]);
  // React.useEffect(() => {

  // }, [reportData]);

  return (
    <>
      {shows === true ? (
        // <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
              <View fixed>
                <View style={styles.section} fixed>
                  <Image style={styles.image} src={logoRect} />
                  <Text style={{ fontSize: "12px", display: "flex" }}>
                    Budget Report - {reportData.projectName}
                  </Text>
                </View>
              </View>
              <View style={{ marginLeft: "25px", marginRight: "25px" }}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Project Name
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        {reportData.projectName}
                      </Text>
                    </View>
                    <View
                      style={{
                        borderBottom: "1px solid black",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Budget Number
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData.budgetName}
                      </Text>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Issuing Date
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {moment(reportData?.IssueDate).format("DD-MM-YYYY")}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      backgroundColor: "#f2f2f2",
                      padding: "0px 3px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "8px",
                      }}
                    >
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        ></Text>
                      </View>

                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Budget Total
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Forecast Cost Total
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Committed To Date
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Balance To Purchase{" "}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          // borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Difference
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      // padding: "0px 3px",
                    }}
                  >
                    {reportData.group.map((ele, index) => {
                      return (
                        <>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              fontSize: "7px",
                              borderBottom: "1px solid black",
                              backgroundColor: "#cccccc",
                            }}
                          >
                            <View
                              style={{
                                width: "20%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele?.groupName}</Text>
                            </View>

                            <View
                              style={{
                                width: "20%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>
                                {ele?.budgetTotal.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "20%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>
                                {ele?.forecastCost.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "20%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>
                                {ele?.commited.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "20%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>
                                {ele?.balanceToPurchase.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "20%",
                                height: "100%",
                                // borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>
                                {ele?.difference.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Text>
                            </View>
                          </View>
                          {ele.subGroup.map((ele) => (
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                fontSize: "7px",
                                
                               
                              }}
                            >
                              <View
                                  style={{
                                    width: "2%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                    padding: "3px",
                                  }}
                               >

                              </View>
                              <View
                                style={{
                                  width: "18%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                  padding: "3px",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <Text>{ele?.subGroupName}</Text>
                              </View>

                              <View
                                style={{
                                  width: "20%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                  padding: "3px",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <Text>
                                  {ele?.budgetTotalSub.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: "20%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                  padding: "3px",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <Text>
                                  {ele?.forecastCostSub.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: "20%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                  padding: "3px",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <Text>
                                  {ele?.commitedSub.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: "20%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                  padding: "3px",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <Text>
                                  {ele?.balanceToPurchaseSub.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: "20%",
                                  height: "100%",
                                  // borderRight: "1px solid black",
                                  padding: "3px",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <Text>
                                  {ele?.differenceSub.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Text>
                              </View>
                            </View>
                          ))}
                        </>
                      );
                    })}
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "7px",
                        backgroundColor: "#cccccc",
                      }}
                    >
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Total</Text>
                      </View>

                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>
                          {budgetTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>
                          {forecastCostTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>
                          {commitedTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>
                          {balanceToPurchaseTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          // borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>
                          {differenceTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.footer} fixed>
                <View
                  style={{ borderTop: "1px solid black", marginRight: "50px" }}
                ></View>
                <View>
                  <Text style={{ fontSize: "8px" }}>
                    PO BOX 282247, Dubai, UAE
                  </Text>
                </View>
                <View>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber, totalPages }) =>
                      `Page # ${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </View>
              </View>
            </Page>
          </Document>
        // </PDFViewer>
      ) : (
        <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
          <PropagateLoader color="blue" />
        </View>
      )}
    </>
  );
};

export default BudgetReport;
