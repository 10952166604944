/* eslint-disable no-unused-vars */
import SearchIcon from "@mui/icons-material/Search";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { Collapse } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import {
  CreateClient,
  GetAllClient,
  GetAllClientPagination,
  GetAllCountryList,
  UpdateClient,
  UpdateClientStatus,
  UploadClient,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  DownloadIcon,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import { ClientForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import Strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_CLIENT_STATUS_FUNCTION,
  CREATE_CLIENT,
  UPDATE_CLIENT,
} from "../../../utils/constants";
import { isEncoded } from "../../../utils/crypto";
import ClientUploadFile from "./ClientUploadFile";
import "./SearchClientPage.css";

export default function SearchClientPage() {
  const Title = Strings["ClientManagement.SearchClientTypeHeader"],
    // addButton = Strings["ClientManagement.SearchClientAddButton"],
    alertMessage = Strings["ClientManagement.AlertMessage"],
    alertTitle = Strings["ClientManagement.AlertTitle"],
    name = Strings["ClientManagement.TableHead.name"],
    clientType = Strings["ClientManagement.TableHead.clientType"],
    // address = Strings["ClientManagement.TableHead.address"],
    // country = Strings["ClientManagement.TableHead.country"],
    phone = Strings["ClientManagement.TableHead.phone"],
    // website = Strings["ClientManagement.TableHead.website"],
    email = Strings["ClientManagement.TableHead.email"],
    activeStatus = Strings["ClientManagement.TableHead.activeStatus"],
    action = Strings["ClientManagement.TableHead.action"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [isShow, setIsShow] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [csvData, setCsvData] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [currentid, setcurrentid] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [searchdropdown, setSearchdropdown] = React.useState();
  const [countryDropdown, setCountryDropdown] = React.useState([]);
  const [totalData, setTotalData] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [optionDropdown, setOptionDropdown] = React.useState();

  // let disableSearch = searchInput === "" || searchdropdown === undefined;

  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_CLIENT)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_CLIENT)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_CLIENT_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: Strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: Strings["Client"],
      path: "master-configuration/searchclient",
    },
  ];

  const mapableData = [
    {
      dataKey: "name",
      title: name,
    },
    {
      dataKey: "client_type.name",
      title: clientType,
    },
    {
      dataKey: "phone",
      title: phone,
    },
    {
      dataKey: "email",
      title: email,
    },
    {
      dataKey: "is_active",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
      isview: true,
    },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };
  const handleChangeActiveStatus = (id, activeStatus) => {
    setAlertBox(true);
    setcurrentStatus(activeStatus);
    setcurrentid(id);
  };

  const openShowModal = () => {
    setIsShow(true);
  };
  const handleUploadClose = () => {
    setMessage(null);
    setIsShow(false);
  };

  const getAlldata = () => {
    setisLoader(true);
    const value = searchdropdown === "country" ? optionDropdown : searchInput;
    const body = {
      [searchdropdown]: value ? value.toString() : null,
    };
    const searchList = open === true ? body : null;
    GetAllClientPagination(rowsPerPage, page, searchValue.value, searchList)
      .then((res) => {
        setisLoader(false);
        const data = res.data.data;
        setRows(data);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  const getTotalClient = () => {
    setisLoader(true);
    GetAllClient()
      .then((res) => {
        setisLoader(false);
        const data = res.data.data;
        setCsvData(data);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getTotalClient();
  }, []);

  React.useEffect(() => {
    getAlldata();
  }, [rowsPerPage, page, open]);

  React.useEffect(() => {
    GetAllCountryList()
      .then((res) => {
        setCountryDropdown(res.data.data);
      })
      .catch(() => {});
  }, []);

  const createClient = (formData) => {
    setisLoader(true);
    CreateClient(formData)
      .then((res) => {
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setRows([res.data.data, ...rows]);
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updateClient = (formData) => {
    setisLoader(true);
    UpdateClient(formData, userId)
      .then((res) => {
        getAlldata();
        setisLoader(false);
        const result = rows.map((ele) => {
          if (ele.id === res.data.data.id) {
            return {
              ...ele,
              name: res.data.data.name,
              address: res.data.data.address,
              city: res.data.data.city,
              client_type_id: res.data.data.client_type_id,
              country_id: res.data.data.country_id,
              email: res.data.data.email,
              fax: res.data.data.fax,
              website: res.data.data.website,
              phone: res.data.data.phone,
            };
          } else {
            return ele;
          }
        });
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setRows(result);
        setisLoader(false);
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const csvUploadClient = (formData) => {
    setisLoader(true);
    UploadClient(formData)
      .then((res) => {
        getAlldata();
        handleUploadClose();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((err) => {
        setisLoader(false);
        setMessage(err.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <ClientForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeFormData={
          clickedBtn === "add" ? createClient : updateClient
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateClientStatus({ status: !currentStatus }, currentid)
      .then((res) => {
        setisLoader(false);
        const result = rows.map((ele) => {
          if (ele.id === res.data.data.id) {
            return {
              ...ele,
              is_active: res.data.data.is_active,
            };
          } else {
            return ele;
          }
        });
        setRows(result);
        setAlertBox(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    setUserId(result.id);
    handleSelectBtn("edit");
  };

  let history = useHistory();

  const handleView = (id) => {
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    setUserId(result.id);
    const client_Id = isEncoded(id);
    history.push({
      pathname: `clientcontactpage/clientcontact/${client_Id}`,
      state: result,
    });
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Address", key: "address" },
    { label: "City", key: "city" },
    { label: "Country", key: "country.name" },
    { label: "Phone", key: "phone" },
    { label: "ContactName", key: "client_contact[0].name" },
    { label: "ContactPhone", key: "client_contact[0].telephone" },
    { label: "ContactMobile", key: "client_contact[0].mobile" },
    { label: "ContactEmail", key: "client_contact[0].email" },
  ];

  const csvReport = {
    data: csvData,
    headers: headers,
    filename: "Client_Report.csv",
  };
  const handleClose = () => {
    setOpen(!open);
    setSearchInput("");
    setSearchdropdown("");
  };
  const handleDataUpload = (e) => {
    openShowModal();
  };

  const uploadModal = () => {
    return (
      <ClientUploadFile
        handleModalClose={handleUploadClose}
        handleChangeData={csvUploadClient}
        errorMessage={errorMessage}
        setMessage={setMessage}
      />
    );
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setisLoader(true);
      GetAllClientPagination(rowsPerPage, page, searchData.value)
        .then((res) => {
          setisLoader(false);
          const data = res.data.data;
          setRows(data);
          setTotalData(res.data.total);
        })
        .catch((e) => {
          setisLoader(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAlldata();
  };
  return (
    <>
      <Helmet>
        <title>Furnish | Clients</title>
      </Helmet>
      <div className="content-wrapper p-0">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>

        <Modal
          onHandleClose={handleUploadClose}
          isOpen={isShow}
          contentStyle={{ width: "50%" }}
        >
          {uploadModal()}
        </Modal>

        <div
          className="row mt-4"
          style={{
            alignItems: "center",
          }}
        >
          <div className="col-4">
            <h3> {Title} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-10 text-end">
                <span
                  style={{
                    marginRight: "-10px",
                  }}
                >
                  <span
                    className="addButton"
                    style={{
                      marginRight: "0px",
                      display: open === true ? "none" : "inline",
                    }}
                  >
                    <IconButton>
                      <Tooltip title="Search">
                        <SearchIcon
                          onClick={() => setOpen(!open)}
                          style={{
                            cursor: "pointer",
                            fill: colors.primary,
                            width: "30px",
                            height: "30px",
                            display: open === true ? "none" : "inline",
                          }}
                        />
                      </Tooltip>
                    </IconButton>
                  </span>
                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="dropdown">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setSearchdropdown(e.target.value)
                              }
                              value={searchdropdown}
                            >
                              <option selected>Select </option>
                              <option value="city">City</option>
                              {/* <option value="client_type">Client Type</option> */}
                              <option value="country">Country</option>
                            </select>
                          </div>
                        </div>
                        {/* {searchdropdown === "client_type" ? (
                          <div className="col-sm-4">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setSearchdropdown(e.target.value)
                              }
                              value={searchdropdown}
                            >
                              <option selected>Select</option>
                              {clientTypeDrop.map((ele) => {
                                return (
                                  <option value={ele.id}>{ele.name}</option>
                                );
                              })}
                            </select>
                          </div>
                        ) : null} */}
                        {searchdropdown === "country" ? (
                          <div className="col-sm-3">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setOptionDropdown(e.target.value)
                              }
                              value={optionDropdown}
                            >
                              <option selected>Select</option>
                              {countryDropdown.map((ele) => {
                                return (
                                  <option value={ele.id}>{ele.name}</option>
                                );
                              })}
                            </select>
                          </div>
                        ) : null}
                        {searchdropdown === "city" ? (
                          <div className="col-sm-3">
                            <input
                              className="form-control me-2"
                              type="search"
                              placeholder="Search"
                              aria-label="Search"
                              value={searchInput}
                              onChange={(e) => setSearchInput(e.target.value)}
                            />
                          </div>
                        ) : null}

                        <div className="col-sm-1 me-5">
                          <button
                            type="button"
                            // disabled={disableSearch}
                            className="btn btn-success"
                            onClick={getAlldata}
                          >
                            Search
                          </button>
                        </div>
                        <div className="col-sm-1">
                          <button
                            type="button"
                            className="btn btn-danger"
                            // onClick={() => setOpen(!open)}
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </span>
              </div>
              {isAdd && (
                <div className="col-1">
                  <span className="addButton">
                    <IconButton
                      onClick={() => {
                        openAddModal();
                        handleSelectBtn("add");
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </span>
                </div>
              )}
              <div className="col-1">
                <span className="downloadButton">
                  <CSVLink style={{ color: "white" }} {...csvReport}>
                    <IconButton>
                      <DownloadIcon />
                    </IconButton>
                  </CSVLink>
                </span>
              </div>
              {/* <div className="col-1">
                <span className="uploadButton">
                  <IconButton>
                    <Tooltip title="Upload">
                      <FileUploadIcon
                        style={{
                          fontSize: "23px",
                          margin: "4px 5px ",
                          cursor: "pointer",
                          color: colors.primary,
                        }}
                        onClick={() => {
                          handleDataUpload("");
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}

          <div className="col-12">
            <div
              className="ClientbudgetTable"
              style={{
                marginBottom: "50px",
              }}
            >
              <form onSubmit={handleSearchSubmit}>
                <FormTextInput
                  type="text"
                  className="searchBarUser mb-3"
                  placeholder={`Search `}
                  Value={searchValue.value}
                  onChangeText={handleSearch}
                  inputStyle={{ border: "none" }}
                  editable
                />
              </form>
              {rows.length > 0 ? (
                <>
                  <SearchTable
                    isUpdate={isUpdate}
                    isStatus={isStatus}
                    placeholder={"name"}
                    data={rows}
                    mapableData={mapableData}
                    searchProp={["name"]}
                    handleChangeActiveStatus={handleChangeActiveStatus}
                    handleChangeEdit={handleChangeEdit}
                    handleView={handleView}
                  />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={totalData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <>
                  {isLoader ? (
                    <LoaderComponent />
                  ) : (
                    <NodataFound
                      onClick={() => {
                        openAddModal();
                        handleSelectBtn("add");
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
