import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { PropagateLoader } from "react-spinners";
import { logoRect } from "../../../assets";
import { GetVendorDataReport } from "../../../axios";

const VendorReport = (props) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      // top: "0",
      right: "-500px",
      fontSize: "8px",
    },
  });

  const { id } = props;
  const [shows, setShows] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);

  React.useEffect(() => {
    GetVendorDataReport(id)
      .then((res) => {
        setReportData(res.data.data);
        setShows(true);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      {shows === true ? (
        // <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page} wrap>
              <View fixed>
                <View style={styles.section} fixed>
                  <Image style={styles.image} src={logoRect} />
                  <Text style={{ fontSize: "12px", display: "flex" }}>
                    Vendor Report -{" "}
                    {reportData.budgetprojectData[0].project.name}
                  </Text>
                </View>
              </View>
              <View style={{ marginLeft: "25px", marginRight: "25px" }}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Project Name
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        {reportData.budgetprojectData[0].project.name}
                      </Text>
                    </View>
                    <View
                      style={{
                        borderBottom: "1px solid black",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Budget Number
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData.budgetprojectData[0].serial_number +
                          " - " +
                          reportData.budgetprojectData[0].project.name}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Issuing Date
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {
                          reportData.budgetprojectData[0].budget_start_date.split(
                            " "
                          )[0]
                        }
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        textAlign: "center",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                        }}
                      >
                        Name
                      </Text>
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                        }}
                      >
                        Address
                      </Text>
                      <Text
                        style={{
                          width: "34%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        Contact
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    {reportData.vendorData &&
                      reportData.vendorData.map((ele, index) => {
                        return (
                          <View
                            style={{
                              fontSize: "9px",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              borderTop: "1px solid black",
                              marginTop: "0px",
                              padding: "0px 3px",
                            }}
                          >
                            <View
                              style={{
                                width: "33%",
                                height: "100%",
                                borderRight: "1px solid black",
                              }}
                            >
                              <Text style={{ padding: "3px 3px" }}>
                                {ele.name}{" "}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "33%",
                                height: "100%",
                                borderRight: "1px solid black",
                              }}
                            >
                              <Text style={{ padding: "3px 3px" }}>
                                {ele.address}
                              </Text>
                            </View>
                            <View style={{ width: "34%", height: "100%" }}>
                              {ele.vendor_contact.map((e) => {
                                return (
                                  <View style={{ padding: "3px 3px" }}>
                                    <Text style={{ textAlign: "left" }}>
                                      {e.name}
                                    </Text>
                                    <Text style={{ textAlign: "left" }}>
                                      Phone: {e.mobile}
                                    </Text>
                                    <Text style={{ textAlign: "left" }}>
                                      Email: {e.email}
                                    </Text>
                                  </View>
                                );
                              })}
                              <View
                                style={{ padding: "3px 3px", fontSize: "9px" }}
                              >
                                {/* <Text style={{ textAlign: "left" }}>Website: {ele.address}</Text> */}
                                {ele.vendor_branch[0] && (
                                  <Text style={{ textAlign: "left" }}>
                                    Branch Telephone:{" "}
                                    {ele.vendor_branch[0]?.phone}
                                  </Text>
                                )}
                              </View>
                            </View>
                          </View>
                        );
                      })}
                  </View>
                </View>
              </View>

              <View style={styles.footer} fixed>
                <View
                  style={{ borderTop: "1px solid black", marginRight: "50px" }}
                ></View>
                <View>
                  <Text style={{ fontSize: "8px" }}>
                    PO BOX 282247, Dubai, UAE
                  </Text>
                  {/* <Text style={{ fontSize: "8px" }}>
                                            tel +971 4 3307725
                                        </Text>
                                        <Text style={{ fontSize: "8px" }}>
                                            <Link style={{ color: "black" }} src="mailto:invitation@furnish-marketplace.com">
                                                invitation@furnish-marketplace.com
                                            </Link>
                                        </Text>
                                        <Text style={{ fontSize: "8px" }}>
                                            <Link style={{ color: "black" }} src="http://furnish-marketplace.com">
                                                furnish-marketplace.com
                                            </Link>
                                        </Text> */}
                </View>
                <View>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber, totalPages }) =>
                      `Page # ${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </View>
              </View>
            </Page>
          </Document>
        // </PDFViewer>
      ) : (
        <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
          <PropagateLoader color="blue" />
        </View>
      )}
    </>
  );
};
export default VendorReport;
