import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { logoRect } from "../../../assets";
import { GetvarianceReport } from "../../../axios";

const VarianceReport = (props) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      // top: "0",
      right: "-500px",
      fontSize: "8px",
    },
  });

  const { id } = props;
  const [shows, setShows] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);

  React.useEffect(() => {
    GetvarianceReport(id)
      .then((res) => {
        setReportData(res.data.data);
        setShows(true);
      })
      .catch((err) => {
        setShows(false);
      });
  }, []);

  return (
    <>
      {shows === true ? (
        // <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
              <View fixed>
                <View style={styles.section} fixed>
                  <Image style={styles.image} src={logoRect} />
                  <Text style={{ fontSize: "12px", display: "flex" }}>
                    Variance Report - {reportData[0].project.name}
                  </Text>
                </View>
              </View>
              <View style={{ marginLeft: "25px", marginRight: "25px" }}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Project Name
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        {reportData[0].project.name}
                      </Text>
                    </View>
                    <View
                      style={{
                        borderBottom: "1px solid black",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Budget Number
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData[0].serial_number +
                          " - " +
                          reportData[0].name}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Issuing Date
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData[0].budget_start_date.split(" ")[0]}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      backgroundColor: "#f2f2f2",
                      padding: "0px 3px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "8px",
                      }}
                    >
                      <View
                        style={{
                          width: "8%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Sub Group</Text>
                      </View>
                      <View
                        style={{
                          width: "8%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Category</Text>
                      </View>
                      <View
                        style={{
                          width: "8%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Control Number</Text>
                      </View>
                      <View
                        style={{
                          width: "8%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Reference</Text>
                      </View>
                      <View
                        style={{
                          width: "8%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Vendor</Text>
                      </View>
                      <View
                        style={{
                          width: "8%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>PO number</Text>
                      </View>
                      <View
                        style={{
                          width: "10%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Short Description</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Budget Qty</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Budget UOM</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Budget Currency</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Budget Unit Price</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Budget Total</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Actual Qty</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Actual UOM</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Actual Currency</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Exchange Rate to AED</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                        }}
                      >
                        <Text>Actual Converted Total</Text>
                      </View>
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                          padding: "3px",
                        }}
                      >
                        <Text>Actual Variance</Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      padding: "0px 3px",
                    }}
                  >
                    {reportData[0].purchase_order_budget_item_details.map(
                      (ele) => {
                        return (
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              fontSize: "7px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <View
                              style={{
                                width: "8%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.itemsubgroup}</Text>
                            </View>

                            <View
                              style={{
                                width: "8%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.itemcategory}</Text>
                            </View>
                            <View
                              style={{
                                width: "8%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.control_number}</Text>
                            </View>
                            <View
                              style={{
                                width: "8%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{"N/A"}</Text>
                            </View>
                            <View
                              style={{
                                width: "8%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.vendor_name}</Text>
                            </View>
                            <View
                              style={{
                                width: "8%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.purchase_order_serial_number}</Text>
                            </View>
                            <View
                              style={{
                                width: "10%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.description}</Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.budget_quantity}</Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.budget_umo_Name}</Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.budget_currency_Name}</Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>
                                {ele.budget_price /
                                  parseInt(ele.budget_quantity)}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.budget_price}</Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.actual_quantity}</Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.actual_uom_Name}</Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>{ele.actual_currency_Name}</Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>
                                {" "}
                                {ele.budget_currency_Name === "AED"
                                  ? ele.actual_currency_Rate
                                  : (
                                      ele.budget_currency_Rev_Rate *
                                      ele.actual_currency_Rate
                                    ).toFixed(2)}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                borderRight: "1px solid black",
                                padding: "3px",
                              }}
                            >
                              <Text>
                                {(
                                  (
                                    (
                                      ele.actual_price / ele.budget_quantity
                                    ).toFixed(2) *
                                    (ele.budget_currency_Name === "AED"
                                      ? ele.actual_currency_Rate
                                      : ele.budget_currency_Rev_Rate *
                                        ele.actual_currency_Rate)
                                  ).toFixed(2) * ele.budget_quantity
                                ).toFixed(2)}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "5%",
                                height: "100%",
                                padding: "3px",
                              }}
                            >
                              <Text>
                                {(
                                  ele.budget_price -
                                  (
                                    (
                                      ele.actual_price / ele.budget_quantity
                                    ).toFixed(2) *
                                    (ele.budget_currency_Name === "AED"
                                      ? ele.actual_currency_Rate
                                      : ele.budget_currency_Rev_Rate *
                                        ele.actual_currency_Rate)
                                  ).toFixed(2) *
                                    ele.budget_quantity
                                ).toFixed(2)}
                              </Text>
                            </View>
                          </View>
                        );
                      }
                    )}
                  </View>
                </View>
              </View>

              <View style={styles.footer} fixed>
                <View
                  style={{ borderTop: "1px solid black", marginRight: "50px" }}
                ></View>
                <View>
                  <Text style={{ fontSize: "8px" }}>
                    PO BOX 282247, Dubai, UAE
                  </Text>
                  {/* <Text style={{ fontSize: "8px" }}>
                                            tel +971 4 3307725
                                        </Text>
                                        <Text style={{ fontSize: "8px" }}>
                                            <Link style={{ color: "black" }} src="mailto:invitation@furnish-marketplace.com">
                                                invitation@furnish-marketplace.com
                                            </Link>
                                        </Text>
                                        <Text style={{ fontSize: "8px" }}>
                                            <Link style={{ color: "black" }} src="http://furnish-marketplace.com">
                                                furnish-marketplace.com
                                            </Link>
                                        </Text> */}
                </View>
                <View>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber, totalPages }) =>
                      `Page # ${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </View>
              </View>
            </Page>
          </Document>
        // </PDFViewer>
      ) : (
        <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
          <PropagateLoader color="blue" />
        </View>
      )}
    </>
  );
};

export default VarianceReport;
