import TablePagination from "@mui/material/TablePagination";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
GetCategoryReport,UpdateCategoryReport,addCategoryInBudgetItem
} from "../../../axios";
import {
  BreadCrumbs,
  Modal,
  AddIcon,
  AlertMessage,
  FormTextInput,
  IconButton,
  NodataFound,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { BudgetItemCategoryForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import "../SearchBudgetItem/BudgetItem.css";
import "./GroupReport.css";
import { getItemFromStorage } from "../../../utils/storage";

const CategoryReport = () => {
    const searchTitle = Strings["ItemCategory.DashboardTitle"],
  alertMessage = Strings["ItemCategory.DeleteMessage"],
  alertTitle = Strings["ItemCategory.AlertTitle"],
  categoryName = Strings["ItemCategory.TableHead.categoryName"],
  description = Strings["ItemCategory.TableHead.description"],
  ActiveStatus = Strings["ItemCategory.TableHead.ActiveStatus"],
  referenceNumber = Strings["ItemCategory.TableHead.reference"],
  action = Strings["ItemCategory.TableHead.Action"];;

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [alertBox, setAlertBox] = React.useState(false);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [categoryId, setCategoryId] = React.useState();
  const params = useParams();
  const [categoryData, setCategoryData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isDelete, setIsDelete] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  
  const location = useLocation();
//   let history = useHistory();
  let proId = location?.state
    ? location.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;
  const fetchId = useParams();
  const BudId = isDecoded(fetchId.budgetID);
  const BudgtId = parseInt(BudId);
  let budget_id = location?.state ? location.state.budget_id : BudgtId;
 

  let scope_id = location?.state ? location?.state?.scope_id : location?.query
  ? JSON.parse(location.query.params).scope_id
  : null;
  let budget_Name = location?.state ? location.state?.budget_name
    : location?.query ? JSON.parse(location.query.params).budget_name
    : null;

  if(scope_id == null || scope_id == undefined && budget_Name == null || budget_Name == undefined && proId == null || proId == undefined && budget_id == null || budget_id == undefined){
      const paramData = JSON.parse(getItemFromStorage("PARAMS"))
      if(paramData){
          budget_id = paramData.budget_id;
          proId = paramData.proId;
          budget_Name = paramData.budget_Name;
          scope_id =  paramData.budget_scope;
      }
  }
  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);

  const paths = [
    {
      name: strings["Projects"],
      path: "project",
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: "Category",
    },
  ];

  const mapableData = [
      {
        dataKey: "categoryName",
        title: categoryName,
      },
      {
        dataKey: "id",
        title: action,
        isAction: true,
      },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const getAllCategory = () => {
    let id = budget_id;
    setisLoader(true);
    GetCategoryReport(id, rowsPerPage, page,searchValue.value)
      .then((res) => {
        const data = res.data.data;
        setisLoader(false);
        setTotalData(res.data.total);
        setCategoryData(data);
      })
      .catch(() => {
        setisLoader(false);
      });
  };


  const createitemCategory = (formData) => {
    setisLoader(true);
    formData['budget_id'] = budget_id;
    addCategoryInBudgetItem(formData)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows([res.data.data, ...rows]);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  React.useEffect(() => {
    getAllCategory();
  }, [rowsPerPage, page]);

 

  const selectModal = () => {
    return (
        <BudgetItemCategoryForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeFormData={
          clickedBtn === "add" ? createitemCategory : ''
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? {} : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

 

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateCategoryReport(categoryId)
      .then((res) => {
        setisLoader(false);
        getAllCategory();
        // const result = rows.map((elem) => {
        //   if (elem.id === res.data.data.id) {
        //     return { ...elem, is_active: res.data.data.is_active };
        //   } else {
        //     return elem;
        //   }
        // });
        setAlertBox(false);
        // setRows(result);
      })
      .catch((e) => {
        setisLoader(false);
      });
    setAlertBox(true);
  };


  const handleChangeDelete = (id) => {
    setAlertBox(true);
    const resultCategory = categoryData.find((res) => {
      return res.id === id;
    });
    setCategoryId(resultCategory.id);

  };
//   const handleSearch = (searchData) => {
//     const data = searchData.value;
//     setSearchValue(searchData);
//     if (searchData.value === "") {
//       setisLoader(true);
//       GetCategoryReport(rowsPerPage, page, searchData.value)
//         .then((res) => {
//           const data = res.data.data;
//           setisLoader(false);
//           setRows(data);
//           setTotalData(res.data.total);
//         })
//         .catch((e) => {
//           setisLoader(false);
//         });
//     }


  return (
    <>
     <>
      <div className="container">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {searchTitle}</h3>
            <BreadCrumbs
              pathList={paths}
              obj={{
                proId: proId,
                budget_id: budget_id,
                budget_name: budget_Name,
                scope_id: scope_id

              }}
            />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          <div className="row">
            {alertBox && (
              <AlertMessage
                title={alertTitle}
                message={alertMessage}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}
            <div className="col-12">
              <div className="UserbudgetTable">
                {/* <form onSubmit={handleSearchSubmit}>
                  <FormTextInput
                    type="text"
                    className="searchBarUser mb-3"
                    placeholder={`Search `}
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    inputStyle={{ border: "none" }}
                    editable
                  />
                </form> */}
                {categoryData.length > 0 ? (
                  <>
                    <SearchTable
                    //   isUpdate={isUpdate}
                    //   isStatus={isStatus}
                      isDelete = {isDelete}
                      placeholder={categoryName}
                      data={categoryData}
                      mapableData={mapableData}
                      searchProp={["categoryName"]}
                      handleChangeDelete={handleChangeDelete}
                    //   handleChangeEdit={handleChangeEdit}
                    />
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <>
                    {isLoader ? (
                      <LoaderComponent />
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    </>
  );
};

export default CategoryReport;


