import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { MdOutlineFileUpload } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  CreateQuotation,
  DeleteQuotation,
  GetAllActiveQuotation,
  UpdateQuotation,
  UploadQuotation,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  NodataFound,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import { Quotation } from "../../../forms/ProjectAndBudget";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_QUOTATION_STATUS_FUNCTION,
  CREATE_QUOTATION,
  UPDATE_QUOTATION,
} from "../../../utils/constants";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import QuotationFileUpload from "./QuotationFileUpload";
import "./SearchQuotation.css";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const
  FurnishLabel = strings["Title.Furnish"],
  QuotationLabel = strings["Title.Quotation"],
  CostBtnLabel = Strings["CostAnalysis.Btn"],
  QuotationTitle = Strings["Quotation.QuotationTitle"],
  alertMessage = Strings["Quotation.AlertMessage"],
  alertTitle = Strings["Quotation.AlertTitle"],
  Action = Strings["Quotation.table.Action"],
  VAlidUntil = Strings["Quotation.table.VAlidUntil"],
  VendorName = Strings["Quotation.table.VendorName"],
  EditTooltip = strings["tooltip.editTooltip"],
  DeletTooltip = strings["Tooltip.deletTooltip"],
  QuotRef = strings["Quotation.TableHead.Reference"],
  QuoSlNo = strings["Quotation.TableHead.SerialNo"],
  CsvBtnLabel = strings["Tooltip.DownloadCSV"]
  ;

const headCells = [
  {
    id: "quotationName",
    numeric: false,
    disablePadding: true,
    label: QuotRef,
  },
  {
    id: "quotation_no",
    numeric: false,
    disablePadding: false,
    label: QuoSlNo,
  },
  {
    id: "vendor",
    numeric: false,
    disablePadding: false,
    label: VendorName,
  },
  {
    id: "validuntil",
    numeric: true,
    disablePadding: false,
    label: VAlidUntil,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: Action,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const SearchQuotation = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [ids, setIds] = React.useState();
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_QUOTATION)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_QUOTATION)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_QUOTATION_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const history = useHistory();
  const location = useLocation();
  const proId = location?.state
    ? location.state.proId
    : location?.query
      ? JSON.parse(location.query.params).proId
      : null;

  const budget_id = location?.state
    ? location.state.budget_id
    : location?.query
      ? JSON.parse(location.query.params).budget_id
      : null;

  const fetchId = useParams();
  const paramId = isDecoded(fetchId.id);
  const paramRfqId = parseInt(paramId);

  const rfqId = location?.state
    ? location.state.rfqId
    : location?.query
      ? JSON.parse(location.query.params).rfqId
      : paramRfqId;

  

  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);

  const paths = [
    {
      name: strings["Projects"],
      path: "project",
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: strings["Rfq"],
      path: `rfq/${encodeBudId}`,
    },
    {
      name: strings["Quotation"],
      path: "/quotation",
    },
  ];
  const openAddModal = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setMessage(null);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const VendorQuoIds = rows.map((ele) => {
    return {
      vendorid: ele.vendor?.id,
      quotation_id: ele.id,
    };
  });

  const getAllQuotation = () => {
    setLoader(true);
    GetAllActiveQuotation(rfqId, rowsPerPage, page)
      .then((res) => {
        setLoader(false);
        const result = res.data.data.map((ele) => ({
          id: ele?.id,
          rfqName: ele.rfq?.name,
          quotationName: ele?.quotation_name,
          itemNo: ele?.budget_item,
          currency: ele?.currency,
          validuntil: ele?.valid_till.split(" ")[0],
          country: ele?.country,
          leadtime: ele?.lead_time,
          activeStatus: ele?.is_active,
          freight: ele?.cost_for_freight_installation,
          duties: ele?.cost_for_duties,
          paymentinfo: ele?.paymentinfo,
          shippinginfo: ele?.incoterm,
          tag: ele?.tags,
          comment: ele?.comment,
          installationIncluded: ele?.installation_included,
          vendor: ele?.vendor,
          vendorname: ele.vendor?.name,
          quotation_no: ele?.quotation_serial_number,
          leadTime: ele?.lead_time.split(" "),
          budget_item: ele?.budget_item,
        }));
        for (let i of result) {
          let itemStrArray = [];
          for (let j of i.itemNo) {
            itemStrArray.push(j.control_number);
          }
          delete i.itemNo;
          i.itemNo = itemStrArray.toString();
        }
        setRows(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  React.useEffect(() => {
    getAllQuotation();
  }, []);

  const createQuotation = (formData) => {
    setLoader(true);
    CreateQuotation(formData)
      .then((res) => {
        getAllQuotation();
        setLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        getAllQuotation();
        handleModalClose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updateQuotation = (formData) => {
    setLoader(true);
    UpdateQuotation(formData, ids)
      .then((res) => {
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        getAllQuotation();
        setLoader(false);

        handleModalClose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const handleChangeSuccess = () => {
    setLoader(true);
    DeleteQuotation(statusChangeId)
      .then((res) => {
        setLoader(false);
        getAllQuotation();
      })
      .catch((e) => {
        setLoader(false);
      });
    setAlertBox(false);
  };

  const uploadQuotation = (selectedFile) => {
    setLoader(true);
    UploadQuotation(selectedFile)
      .then((res) => {
        if (res.data.code === "200") {
          getAllQuotation();
          setLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          handleModalClose();
        } else if (res.data.code === "409") {
          getAllQuotation();
          setLoader(false);
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
          handleModalClose();
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const selectModal = () => {
    if (clickedBtn === "add") {
      return (
        <Quotation
          rfqId={rfqId}
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleChangeData={createQuotation}
          actionType={clickedBtn}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <Quotation
          rfqId={rfqId}
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleChangeData={updateQuotation}
          actionType={clickedBtn}
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "CSV File") {
      return (
        <QuotationFileUpload
          handleChangeData={uploadQuotation}
          handleModalClose={handleModalClose}
          rfqId={rfqId}
        />
      );
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDeleteAction = (id) => {
    setStatusChangeId(id);
    setAlertBox(true);
  };
  const handleChangeEdit = (id) => {
    setIds(id);
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handlecsvUploadItem = (id) => {
    openAddModal();
    handleSelectBtn("CSV File");
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const rfq_Id = isEncoded(rfqId);


  return (
    <>
     <div className="content-wrapper p-0">
        <Helmet>
          <title>{FurnishLabel} | {QuotationLabel}</title>
        </Helmet>
        {loader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div
          className="row mt-4 mb-4 d-flex"
          style={{
            alignItems: "center",
          }}
        >
          <div className="col-md-6">
            <h3> {QuotationTitle} </h3>
            <BreadCrumbs
              pathList={paths}
              obj={{ 
                proId: proId, 
                budget_id: budget_id,
                rfq_Id: rfqId
              
              }}
            />
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            {rows.length > 0 ? (
              <button
                className="budgetitembutton me-3"
                onClick={() => {
                  history.push({
                    pathname: `/costanalysis/${rfq_Id}`,
                    state: {
                      rfqId: rfqId,
                      data: VendorQuoIds,
                      proId: proId,
                      budget_id: budget_id,
                    },
                  });
                }}
                buttonStyle={{
                  backgroundColor: colors.darkTransparent,
                  marginRight: "-400px",
                }}
                disable={rows.length > 0 ? false : true}
              >
                {CostBtnLabel}
              </button>
            ) : (
              <></>
            )}
            {isAdd && (
              <Tooltip title={CsvBtnLabel} placement="bottom">
                <span className="addButton">
                  <IconButton>
                    <MdOutlineFileUpload
                      style={{
                        fontSize: "23px",
                        margin: "4px 5px ",
                        cursor: "pointer",
                        color: colors.primary,
                      }}
                      onClick={() => {
                        handlecsvUploadItem("");
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {isAdd && (
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            )}
          </div>
        </div>

        {alertBox && (
          <AlertMessage
            title={alertTitle}
            message={alertMessage}
            onChangeCancel={handleChangeCancel}
            onChangeSucess={handleChangeSuccess}
          />
        )}
        {rows.length > 0 ? (
          <>
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.name);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.name}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox"></TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.quotationName}
                              </TableCell>
                              <TableCell align="left">
                                {row.quotation_no}
                              </TableCell>
                              <TableCell align="left">
                                {row.vendorname}
                              </TableCell>
                              <TableCell align="left">
                                {row.validuntil}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={{ paddingLeft: "5px" }}
                              >
                                {isUpdate && (
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      openAddModal();
                                      setCurrentType(row);
                                      handleSelectBtn("edit");
                                      handleChangeEdit(row.id);
                                    }}
                                  >
                                    <Tooltip title={EditTooltip}>
                                      <EditIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                                {isStatus && (
                                  <IconButton
                                    onClick={() => {
                                      setCurrentType(row.id);
                                      handleDeleteAction(row.id);
                                    }}
                                  >
                                    <Tooltip title={DeletTooltip}>
                                      <DeleteIcon
                                        sx={{ color: colors.danger }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <>
            {loader ? (
              <LoaderComponent />
            ) : (
              <NodataFound
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SearchQuotation;
