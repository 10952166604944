import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import swal from "sweetalert";
import {
  CreateClientType,
  GetAllClientType,
  UpdateClientType,
  UpdateClientTypeStatus,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { ClientTypeForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_CLIENT_TYPE_STATUS_FUNCTION,
  CREATE_CLIENT_TYPE_FUNCTION,
  UPDATE_CLIENT_TYPE_FUNCTION,
} from "../../../utils/constants";

export default function SearchClientTypePage() {
  const Title = Strings["ClientType.SearchClientTypeHeader"],
    alertMessage = Strings["ClientType.AlertMessage"],
    alertTitle = Strings["ClientType.AlertTitle"],
    clientType = Strings["ClientType.TableHead.clientType"],
    Description = Strings["ClientType.TableHead.Description"],
    tag = Strings["ClientType.TableHead.tag"],
    activeStatus = Strings["ClientType.TableHead.activeStatus"],
    action = Strings["ClientType.TableHead.action"];
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState(null);
  const [alertBox, setAlertBox] = React.useState(false);
  // const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [userId, setUserId] = React.useState();
  const [errorMessage, setMessage] = React.useState(null);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [currentid, setcurrentid] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);

  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_CLIENT_TYPE_FUNCTION)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_CLIENT_TYPE_FUNCTION)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_CLIENT_TYPE_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["ClientTypeText"],
    },
  ];

  const mapableData = [
    {
      dataKey: "name",
      title: clientType,
    },
    {
      dataKey: "description",
      title: Description,
    },
    {
      dataKey: "tag",
      title: tag,
    },
    {
      dataKey: "is_active",
      title: activeStatus,
      isStatus: true,
    },

    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const handleChangeActiveStatus = (id, activeStatus) => {
    setAlertBox(true);
    setcurrentStatus(activeStatus);
    setcurrentid(id);
  };
  const getAllClientType = () => {
    setisLoader(true);
    GetAllClientType(rowsPerPage, page, searchValue.value)
      .then((res) => {
        setRows(res.data.data);
        setTotalData(res.data.total);
        setisLoader(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getAllClientType();
  }, [rowsPerPage, page]);

  React.useEffect(() => {}, [rows]);

  const createClientType = (formData) => {
    setisLoader(true);
    CreateClientType(formData)
      .then((res) => {
        getAllClientType();
        if (res.status === 200) {
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          // setRows([res.data.data, ...rows]);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updateClientType = (formData) => {
    setisLoader(true);
    UpdateClientType(formData, userId)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          const result = rows.map((ele) => {
            if (ele.id === res.data.data.id) {
              return {
                ...ele,
                name: res.data.data.name,
                description: res.data.data.description,
                tag: res.data.data.tag,
              };
            } else {
              return ele;
            }
          });
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows(result);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <ClientTypeForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeFormData={
          clickedBtn === "add" ? createClientType : updateClientType
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateClientTypeStatus({ status: !currentStatus }, currentid)
      .then((res) => {
        setisLoader(false);
        const result = rows.map((ele) => {
          if (ele.id === res.data.data.id) {
            return {
              ...ele,
              is_active: res.data.data.is_active,
            };
          } else {
            return ele;
          }
        });
        setRows(result);
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    setUserId(result.id);
    handleSelectBtn("edit");
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setisLoader(true);
      GetAllClientType(rowsPerPage, page, searchData.value)
        .then((res) => {
          setRows(res.data.data);
          setTotalData(res.data.total);
          setisLoader(false);
        })
        .catch((e) => {
          setisLoader(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAllClientType();
  };
  return (
    <>
      <div className="container">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {Title} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
        </div>

        <div className="row">
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}
          <div className="col-12">
            <div className="UserbudgetTable">
              <form onSubmit={handleSearchSubmit}>
                <FormTextInput
                  type="text"
                  className="searchBarUser mb-3"
                  placeholder={`Search `}
                  Value={searchValue.value}
                  onChangeText={handleSearch}
                  inputStyle={{ border: "none" }}
                  editable
                />
              </form>
              {rows ? (
                <>
                  <SearchTable
                    isUpdate={isUpdate}
                    isStatus={isStatus}
                    placeholder={"client type"}
                    data={rows}
                    mapableData={mapableData}
                    searchProp={["name"]}
                    handleChangeActiveStatus={handleChangeActiveStatus}
                    handleChangeEdit={handleChangeEdit}
                  />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={totalData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <>
                  {isLoader ? (
                    <LoaderComponent />
                  ) : (
                    <NodataFound
                      onClick={() => {
                        openAddModal();
                        handleSelectBtn("add");
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
