/* eslint-disable no-unused-vars */
import TablePagination from "@mui/material/TablePagination";
import React from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  DeleteRequestForInformation,
  GetAllRFI,
  GetRfi,
  UpdateRfi,
  UpdateRFIStatus,
} from "../../../axios";

import {
  AlertMessage,
  BreadCrumbs,
  Modal,
  NodataFound,
  RFITable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import {
  RequestforInformationPageForm,
  RfiForm,
  RfqForm,
} from "../../../forms/ProjectAndBudget";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_RFI_STATUS_FUNCTION,
  UPDATE_RFI,
} from "../../../utils/constants";
// import ExcelExportRfi from "./ExcelExportRfi";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import "./SearchDocumentRfi.css";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import { getItemFromStorage } from "../../../utils/storage";

const SearchDoumentRfi = () => {
  const
    furnishTitle = strings["Title.Furnish"],
    furnishRFI = strings["Title.RFI"],
    RFITitle = Strings["Document.RFITitle"],
    rfiname = Strings["Document.tableHead.tableName"],
    action = Strings["Document.tableHead.action"],
    RfiAlert = Strings["Document.rfiAlert"],
    RfiAlertMessage = strings["Document.rfiAlertMessage"],
    RFINoLabel = strings["Document.rfiNo"],
    RFISend = strings["Document.RFISend"],
    RfiPlaceyholder = Strings["Document.RFI.placeholder"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [alertBox, setAlertBox] = React.useState(false);
  const [rfiAlertBox, setRfiAlertBox] = React.useState(false);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [rfiArray, setRfiArray] = React.useState([]);
  // const [RfqId, setRfqId] = React.useState();
  const [RfiId, setRfiId] = React.useState();
  const params = useParams();
  // const [rfqData, setRFQData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [datas, setDatas] = React.useState();
  const [status, setStatus] = React.useState();

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_RFI)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_RFI_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const location = useLocation();

  let proId = location.state
    ? location.state.proId
    : location.query
      ? JSON.parse(location.query.params).proId
      : null;
 

  const fetchId = useParams();
  const BudId = isDecoded(fetchId.budgetID);
  const BudgtId = parseInt(BudId);

  let budget_id = location?.state ? location.state.budget_id : BudgtId;

  

  let scope_id = location?.state ? location?.state?.scope_id : location?.query
  ? JSON.parse(location.query.params).scope_id
  : null;
  let budget_Name = location?.state ? location.state?.budget_name
    : location?.query ? JSON.parse(location.query.params).budget_name
    : null;

  if(scope_id == null || scope_id == undefined && budget_Name == null || budget_Name == undefined && proId == null || proId == undefined && budget_id == null || budget_id == undefined){
      const paramData = JSON.parse(getItemFromStorage("PARAMS"))
      if(paramData){
          budget_id = paramData.budget_id;
          proId = paramData.proId;
          budget_Name = paramData.budget_Name;
          scope_id =  paramData.budget_scope;
      }
  }
  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);



  const paths = [
    {
      name: strings["Projects"],
      path: "project",
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: strings["RFI"],
      path: "searchdocument",
    },
  ];
  const mapableData = [
    {
      dataKey: "name",
      title: rfiname,
    },
    {
      dataKey: "rfiNumber",
      title: RFINoLabel,
    },
    {
      dataKey: "designer",
      title: RFISend,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
      isDownload: true,
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const getRfi = () => {
    setisLoader(true);
    GetAllRFI(budget_id, rowsPerPage, page)
      .then((res) => {
        setTotalData(res.data.total);
        setisLoader(false);
        const result = res.data.data.map((elm) => ({
          id: elm.id,
          name: elm.name,
          notes: elm.notes,
          designer: elm.sendto.map((elem) => {
            return `${elem.rfi_send_to} ,`.replace(/,\s*$/, "");
          }),
          deadline: elm.deadline?.split(" ")[0],
          description: elm.description,
          rfiNumber: elm.rfi_number,
          selectedFile: elm.files,
          budget_item: elm.budget_item,
        }));
        for (let i of result) {
          let selectedFile = [];
          for (let j of i.selectedFile) {
            selectedFile.push(j.file_path);
          }
          i.selectedFile = selectedFile.toString();
        }
        setRfiArray(result);
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  React.useEffect(() => {
    getRfi();
  }, [rowsPerPage, page]);

  const updateRfi = (formData) => {
    setisLoader(true);
    UpdateRfi(formData, RfiId)
      .then((res) => {
        getRfi();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    if (clickedBtn === "addrfq") {
      return (
        <RfqForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          budget_id={params}
          handleChangeData={(val) => { }}
          actionType={clickedBtn}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "addrfi") {
      return (
        <RequestforInformationPageForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleChangeData={(val) => { }}
          actionType={clickedBtn}
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <RfiForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          budget_id={BudgtId}
          handleChangeData={updateRfi}
          // actionType={clickedBtn}
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    }
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleChangeSucessRfi = () => {
    setRfiAlertBox(false);
    DeleteRequestForInformation(RfiId);
    setisLoader(true);
    DeleteRequestForInformation(RfiId)
      .then((res) => {
        getRfi();
        setisLoader(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const handleChangeEditRFI = (id) => {
    const result = rfiArray.find((res) => {
      return res.id === id;
    });
    setRfiId(result.id);
    setCurrentType(result);
    openAddModal();
    handleSelectBtn("edit");
  };

  const handleChangeDeleteRfi = (id) => {
    const resultRfi = rfiArray.find((res) => {
      return res.id === id;
    });
    setRfiId(resultRfi.id);
    setRfiAlertBox(true);
  };

  let history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const isChangeStatus = (isChangeStatus) => {
    let body = { status: isChangeStatus };
    UpdateRFIStatus(datas, body)
      .then((res) => {
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setStatus("");
        setDatas("");
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  const handleChangeStatus = (id) => {
    setDatas(id);
  };
  const createDownLoadData = (rowData, result2, result3) => {
    handleExport(rowData, result2, result3).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "rfi_report.xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }
    return buf;
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = (rowData, result2, result3) => {
    const title = [
      {
        A: "Furnish                                     Request For Information",
      },
    ];

    let table1 = [
      // {},
      {
        A: "PROJECT NAME:",
        B: rowData.PROJECTNAME,
      },
      {
        A: "",
      },
      {
        A: "DATE:",
        B: rowData.DATE,
      },

      {
        A: "",
      },
      {
        A: "DESIGNER:",
        B: result3,
      },

      {
        A: "",
      },
      {
        A: "RFI#",
        B: rowData.RFI,
      },
      {
        A: "",
      },
      {
        A: "Spec#",
      },
    ];
    let table2 = [
      // {
      //   A: "BudgetItemName",
      //   B: "Description",
      // },
    ];

    result2.forEach((row) => {
      table2.push(
        {},
        {
          A: row.BudgetItemName,
          B: row.Description,
        },
        {},
        {
          B: "Response:",
        },
        {}
      );
    });

    let table6 = [
      {
        A: "",
      },
      {
        A: "PLEASE NOTE:",
      },
      {
        A: "",
      },
      {
        A: rowData.Spec,
      },
      {
        A: "",
      },
      {
        A: "RECEIVED BY CLIENT:",
      },
      {
        A: "",
      },
      {
        A: "Name",
        B: "Signature",
        C: "Date",
      },
    ];

    table1 = [{ A: "" }].concat(table1).concat(table2).concat(table6);

    const finalData = [...title, ...table1];
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, sheet, "rfi_report");

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "PROJECTNAME"
        ? headerIndexes.push(index)
        : data["A"] === "DATE:"
          ? headerIndexes.push(index)
          : data["A"] === "DESIGNER:"
            ? headerIndexes.push(index)
            : data["A"] === "RFI#"
              ? headerIndexes.push(index)
              : data["A"] === "Spec#"
                ? headerIndexes.push(index)
                : null
    );



    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:C1",
      tbodyRange: `A3:B${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] - 1}:A${headerIndexes[0] - 1}`
          : null,
      theadRange1:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] - 1}:A${headerIndexes[1] - 1}`
          : null,
      theadRange2:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 1}:A${headerIndexes[1] + 1}`
          : null, wrapText: "true",
      theadRange3:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 3}:A${headerIndexes[1] + 3}`
          : null,
      theadRange4:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 5}:A${headerIndexes[1] + 5}`
          : null,
      plaseNote:
        headerIndexes?.length >= 2
          ? `A${finalData.length - 6}:A${finalData.length - 6}`
          : null,
      ClientHeader:
        headerIndexes?.length >= 2
          ? `A${finalData.length - 2}:A${finalData.length - 2}`
          : null,
      itemList:
        headerIndexes?.length >= 2
          ? `A12:B${finalData.length - 7}`
          : null,
      plaseRow:
        headerIndexes?.length >= 2
          ? `${finalData.length - 6}`
          : null,
      ClientRow:
        headerIndexes?.length >= 2
          ? `${finalData.length - 2}`
          : null,
      signRow:
        headerIndexes?.length >= 2
          ? `${finalData.length - 1}`
          : null,
      FooterRow:
        headerIndexes?.length >= 2
          ? `${finalData.length}`
          : null,
      Footer:
        headerIndexes?.length >= 2
          ? `A${finalData.length}:C${finalData.length}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.column("A").width(40);
        sheet.column("B").width(40);
        sheet.column("C").width(15);

        sheet.row(1).height(50);
        sheet.row(2).height(10);
        sheet.row(3).height(20);
        sheet.row(5).height(20);
        sheet.row(7).height(20);
        sheet.row(9).height(20);
        sheet.row(11).height(20);
        sheet.row(11).height(20);
        sheet.row(dataInfo.plaseRow).height(20);
        sheet.row(dataInfo.ClientRow).height(20);
        sheet.row(dataInfo.signRow).height(40);
        sheet.row(dataInfo.FooterRow).height(15);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontSize: 22,
          fill: "92d050",
          fontColor: "000000",
          border: "thin",
        });
        sheet.range("B11:B11").merged(true).value("Query:").style({
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fill: "d1f779",
          fontSize: 12,
          fontColor: "000000",
          border: "thin",
        });

        sheet.range(dataInfo.Footer).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fill: "d9d9d9",
          fontSize: 12,
          fontColor: "000000",
          border: "thin",
        });

        sheet.range(dataInfo.theadRange).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "d1f779",
          fontColor: "000000",
          bold: true,
          border: "thin",
        });
        sheet.range(dataInfo.theadRange1).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "d1f779",
          fontColor: "000000",
          bold: true,
          border: "thin",
        });
        sheet.range(dataInfo.theadRange2).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "d1f779",
          fontColor: "000000",
          bold: true,
          border: "thin",
        });
        sheet.range(dataInfo.theadRange3).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "d1f779",
          fontColor: "000000",
          bold: true,
          border: "thin",
        });
        sheet.range(dataInfo.theadRange4).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "d1f779",
          fontColor: "000000",
          bold: true,
          border: "thin",
        });
        sheet.range(dataInfo.plaseNote).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "d1f779",
          fontColor: "000000",
          bold: true,
          border: "thin",
        });
        sheet.range(dataInfo.ClientHeader).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "d1f779",
          fontColor: "000000",
          bold: true,
          border: "thin",
        });
        sheet.range(dataInfo.itemList).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 10,
          fontColor: "000000",
          wrapText: "true",
        });
      });
      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  const handleRfiDownload = (id) => {
    GetRfi(id)
      .then((res) => {
        const result2 = res.data.data.budget_item.map((elm) => ({
          BudgetItemName: elm.budgetitem.description,
          Description: elm.description,
        }));
        const DESIGNERs = res.data.data.send_to.map((elem) => {
          return elem.rfi_send_to;
        });
        const result3 = DESIGNERs.join(",");
        const result = {
          PROJECTNAME: res.data.data.budget.project.name,
          DATE: res.data.data.created_on.slice(0, 10),
          RFI: res.data.data.name,
          Spec: res.data.data.notes,
        };
        createDownLoadData(result, result2, result3);
      })
      .catch(() => {
      });
  };

  return (
    <div className="content-wrapper p-0">
      <Helmet>
        <title>{furnishTitle} | {furnishRFI}</title>
      </Helmet>
      {isLoader ? <LoaderComponent /> : null}
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>

      <div className="row mt-4">
        <h3> {RFITitle} </h3>
        <BreadCrumbs
          pathList={paths}
          obj={{
            proId: proId,
            budget_id: budget_id,
            budget_name: budget_Name,
            scope_id: scope_id
          }}
        />
      </div>
      {rfiAlertBox && (
        <AlertMessage
          title={RfiAlert}
          message={RfiAlertMessage}
          onChangeCancel={handleChangeCancel}
          onChangeSucess={handleChangeSucessRfi}
        />
      )}

      <div className="RfiTable">
        {rfiArray.length > 0 ? (
          <>
            <RFITable
              isUpdate={isUpdate}
              isStatus={isStatus}
              isChngStatus={handleChangeStatus}
              isChangeStatus={isChangeStatus}
              placeholder={RfiPlaceyholder}
              data={rfiArray}
              mapableData={mapableData}
              searchProp={["name"]}
              handleChangeDelete={handleChangeDeleteRfi}
              handleChangeEdit={handleChangeEditRFI}
              handleRfiDownload={handleRfiDownload}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <>
            {isLoader ? <LoaderComponent /> : <NodataFound norecord={false} />}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchDoumentRfi;



// wrapText: "true",