/* eslint-disable no-unused-vars */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Card
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";
import { CSVLink } from "react-csv";
import { useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  CreateClientContact,
  GetClientContact,
  UpdateClientContactActiveStatus,
  UpdatedClientContact,
} from "../../../axios";
import {
  AlertMessage,
  BreadCrumbs,
  Chip,
  DownloadIcon,
  IconButton,
  Modal,
  NodataFound,
  SwitchButton,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import { ClientContactForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isDecoded, isEncoded } from "../../../utils/crypto";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // {
  //   id: "profile",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Profile Pic",
  // },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "designation",
    numeric: false,
    disablePadding: false,
    label: "Designation",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "Active Status",
    numeric: false,
    disablePadding: false,
    label: "Active Status",
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> */}
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ClientContactPage() {
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const fetchId = useParams();
  const paramId = isDecoded(fetchId.clientid);
  
  const client_Id = parseInt(paramId);
  const encodeClientId = isEncoded(client_Id);
  const location = useLocation();
  const userData = location.state;

  const alertMessage = Strings["ItemGroup.AlertMessage"],
    alertTitle = Strings["ItemGroup.AlertTitle"];
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentClientContact, setCurrentClientContact] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [statusCode, setStatusCode] = React.useState();

  const paths = [
    {
      name: Strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: "Clients",
      path: "master-configuration/searchclient",
    },
    // {
    //   name: strings["ClientProfile"],
    //   path: `master-configuration/clientcontactpage/${encodeClientId}`,
    // },
    {
      name: strings["ClientContacts"],
      path: "",
    },
  ];

  const handleChangeActiveStatus = (id) => {
    const result = rows.find((elem) => {
      return elem.id === id;
    });
    setStatusCode(result.is_active);
    setStatusChangeId(id);
    setAlertBox(true);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateClientContactActiveStatus({ status: 1 - statusCode }, statusChangeId)
      .then((res) => {
        setisLoader(false);
        const activestatusresult = rows.map((ele) => {
          if (ele.id === res.data.data.id) {
            return {
              ...ele,
              is_active: res.data.data.is_active,
            };
          } else {
            return ele;
          }
        });
        setRows(activestatusresult);
        setAlertBox(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const createClientContactForm = (formData) => {
    setisLoader(true);
    CreateClientContact(formData)
      .then((res) => {
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        getAllClient();
        handleModalClose();
      })
      .catch((e) => {
        setMessage(e.response.data.message);
        setisLoader(false);
      });
  };
  const updateClientContactForm = (formData) => {
    let id = currentClientContact.id;
    setisLoader(true);
    UpdatedClientContact(formData, id)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          const results = rows.map((ele) => {
            if (ele.id === res.data.data.id) {
              return {
                ...ele,
                name: res.data.data.name,
                client_id: res.data.data.client_id,
                designation: res.data.data.designation,
                telephone: res.data.data.telephone,
                mobile: res.data.data.mobile,
                email: res.data.data.email,
                profile_picture: res.data.data.profile_picture,
              };
            } else {
              return ele;
            }
          });
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows(results);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const getAllClient = () => {
    setisLoader(true);
    GetClientContact(client_Id)
      .then((res) => {
        setisLoader(false);
        setRows(res.data.data);
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response?.data.message);
      });
  };

  React.useEffect(() => {
    getAllClient();
  }, []);

  const selectModal = () => {
    return (
      <ClientContactForm
        handleChangeFormData={
          clickedBtn === "add"
            ? createClientContactForm
            : updateClientContactForm
        }
        initialValue={clickedBtn === "add" ? {} : currentClientContact}
        actionType={clickedBtn}
        handleModalClose={handleModalClose}
        client={client_Id}
        errorMessage={errorMessage}
        setMessage={setMessage}
      />
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const headers = [
    { label: "Name", key: "name" },
    { label: "Designation", key: "designation" },
    { label: "Phone No.", key: "mobile" },
  ];
  const csvReport = {
    data: rows,
    headers: headers,
    filename: "Clientscontact_Report.csv",
  };
  console.log(rows);
  return (
    <>
      {isLoader ? (
        <LoaderComponent />
      ) : (
        <div className="container">
          <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
            {selectModal()}
          </Modal>
          <div
            className="row"
            style={{
              alignItems: "center",
            }}
          >
            <div className="col-5 mt-3">
              <h3> {"Client Contacts"} </h3>
              <BreadCrumbs pathList={paths} obj={userData} />
            </div>
            <div
              className="col-7"
              style={{
                marginTop: "20px",
                textAlign: "end",
                marginBottom: 20,
              }}
            >
              {alertBox && (
                <AlertMessage
                  title={alertTitle}
                  message={alertMessage}
                  onChangeCancel={handleChangeCancel}
                  onChangeSucess={handleChangeSuccess}
                />
              )}
              <div>
                {rows.length !== 0 ? (
                  <span className="downloadButton mx-3">
                    <CSVLink style={{ color: "white" }} {...csvReport}>
                      <IconButton>
                        <DownloadIcon />
                      </IconButton>
                    </CSVLink>
                  </span>
                ) : null}

                <span className="addButton">
                  <IconButton
                    onClick={() => {
                      openAddModal();
                      handleSelectBtn("add");
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon
                        sx={{ fontSize: "1.2em", color: colors.primary }}
                      />
                    </Tooltip>
                  </IconButton>
                </span>
              </div>
            </div>
            <div
              className="col-5"
            >
              
            </div>
            <div className="row d-flex flex-row justify-content-center mt-2">
              <div className="col-12">
              {rows ? (             <Card>
                                    <>
                                      <div style={{"margin": "10px", "padding": "10px"}}>
                                        <div className="row">
                                          <div className="col-12">
                                      <h6
                                        className="profiledataname mb-1"
                                      >
                                        {userData.name
                                          ? userData?.name.slice(0, 30)
                                          : "NA"}
                                      </h6>
                                      </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-4">
                                      <h6 className="mb-3">
                                        {userData.website
                                          ? userData?.website
                                          : "NA"}
                                      </h6>
                                      </div>
                                      <div className="col-4">
                                      <h6  className="mb-3">
                                        {userData.phone
                                          ? `Phone: ${userData?.phone}`
                                          : "NA"}
                                      </h6>
                                      </div>
                                      <div className="col-4">
                                      <h6  className="mb-3">
                                        {userData.fax ? `Fax: ${userData?.fax}` : "NA"}
                                      </h6>
                                      </div>
                                      <div className="row">
                                      <div className="col-8">
                                      <h6>
                                        {userData?.address ? `Address: ${userData?.address
                                          .slice(0, 20)
                                          .concat("...")}`: "NA"}
                                        
                                      </h6>
                                      </div>
                                      <div className="col-4">
                                        <h6>
                                      {userData?.city ? `City: ${userData.city}` : "NA"}
                                      </h6>
                                      </div>
                                      </div>
                                      </div>
                                      </div>
                                    </>

                                    </Card>
                                  ) : null}
          
              </div>
            </div>
            {rows.length > 0 ? (
              <>
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%", mb: 10, mt: 5 }}>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />
                        <TableBody>
                          {stableSort(rows, getComparator(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.name);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.name}
                                  selected={isItemSelected}
                                >
                                  <TableCell padding="checkbox"></TableCell>
                                    
                                  <TableCell align="left">{row.name}</TableCell>
                                  <TableCell align="left">
                                    {row.designation}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.mobile}
                                  </TableCell>

                                  <TableCell align="left">
                                    <Chip
                                      label={
                                        row.is_active ? "Active" : "Inactive"
                                      }
                                      type={
                                        row.is_active ? "active" : "inactive"
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                    <IconButton
                                      aria-label="edit"
                                      onClick={() => {
                                        openAddModal();
                                        handleSelectBtn("edit");
                                        setCurrentClientContact(row);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <SwitchButton
                                      checked={row.is_active}
                                      onClick={() => {
                                        handleChangeActiveStatus(row.id);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow
                              style={{
                                height: (dense ? 33 : 53) * emptyRows,
                              }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box>
              </>
            ) : (
              <>
                {isLoader ? (
                  <LoaderComponent />
                ) : (
                  <NodataFound
                    onClick={() => {
                      openAddModal();
                      handleSelectBtn("add");
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ClientContactPage;
