import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import swal from "sweetalert";
import {
  CreateCountryType,
  GetAllCountryList,
  GetCountryType,
  UpdateCountryTypeActiveStatus,
  UpdatedCountryType,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { CountryForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";

import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_COUNTRY_STATUS_FUNCTION,
  CREATE_COUNTRY_FUNCTION,
  UPDATE_COUNTRY_FUNCTION,
} from "../../../utils/constants";

export default function SearchCountryPage() {
  const searchTitle = Strings["Country.SearchCountryHeader"],
    alertMessage = Strings["Country.AlertMessage"],
    alertTitle = Strings["Country.AlertTitle"],
    name = Strings["Country.TableHead.name"],
    code = Strings["Country.TableHead.code"],
    tag = Strings["Country.TableHead.tag"],
    activeStatus = Strings["Country.TableHead.activeStatus"],
    action = Strings["Country.TableHead.action"];
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [editId, setEditId] = React.useState(null);
  const [alertBox, setAlertBox] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [currentid, setcurrentid] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [allCountry, setAllCountry] = React.useState([]);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_COUNTRY_FUNCTION)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_COUNTRY_FUNCTION)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_COUNTRY_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["CountryText"],
    },
  ];
  const mapableData = [
    {
      dataKey: "name",
      title: name,
    },
    {
      dataKey: "code",
      title: code,
    },
    {
      dataKey: "tag",
      title: tag,
    },
    {
      dataKey: "is_active",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };

  const createCountryType = (formData) => {
    setisLoader(true);
    CreateCountryType(formData)
      .then((res) => {
        getAlldata();
        if (res.status === 200) {
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          // setRows([res.data.data, ...rows]);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updateCountryType = (formData) => {
    setisLoader(true);
    UpdatedCountryType(formData, editId)
      .then((res) => {
        if (res.status === 200) {
          getAlldata();
          setisLoader(false);
          const result = rows.map((elem) => {
            if (elem.id === res.data.data.id) {
              return {
                ...elem,
                name: res.data.data.name,
                code: res.data.data.code,
                tag: res.data.data.tag,
              };
            } else {
              return elem;
            }
          });
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows(result);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const getAlldata = () => {
    setisLoader(true);
    GetCountryType(rowsPerPage, page, searchValue.value)
      .then((res) => {
        setisLoader(false);
        const data = res.data.data;
        setRows(data);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  React.useEffect(() => {
    getAlldata();
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    setisLoader(true);
    GetAllCountryList()
      .then((res) => {
        setisLoader(false);
        const data = res.data.data;
        setAllCountry(data);
      })
      .catch((e) => {
        setisLoader(false);
      });
  }, []);

  const selectModal = () => {
    return (
      <CountryForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeData={
          clickedBtn === "add" ? createCountryType : updateCountryType
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  const handleChangeActiveStatus = (id, statuss) => {
    setAlertBox(true);
    setcurrentStatus(statuss);
    setcurrentid(id);
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateCountryTypeActiveStatus({ status: 1 - currentStatus }, currentid)
      .then((res) => {
        setisLoader(false);
        const result = rows.map((elem) => {
          if (elem.id === res.data.data.id) {
            return { ...elem, is_active: res.data.data.is_active };
          } else {
            return elem;
          }
        });
        setRows(result);
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    setEditId(result.id);
    handleSelectBtn("edit");
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setisLoader(true);
      GetCountryType(rowsPerPage, page, searchData.value)
        .then((res) => {
          setisLoader(false);
          const data = res.data.data;
          setRows(data);
          setTotalData(res.data.total);
        })
        .catch((e) => {
          setisLoader(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAlldata();
  };
  return (
    <>
      <div className="container">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div
          className="row mt-4"
          style={{
            alignItems: "center",
          }}
        >
          <div className="col">
            <h3> {searchTitle} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          <div>
            <div className="row">
              {alertBox && (
                <AlertMessage
                  title={alertTitle}
                  message={alertMessage}
                  onChangeCancel={handleChangeCancel}
                  onChangeSucess={handleChangeSuccess}
                />
              )}
              <div className="col-12">
                <div className="UserbudgetTable">
                  <form onSubmit={handleSearchSubmit}>
                    <FormTextInput
                      type="text"
                      className="searchBarUser mb-3"
                      placeholder={`Search `}
                      Value={searchValue.value}
                      onChangeText={handleSearch}
                      inputStyle={{ border: "none" }}
                      editable
                    />
                  </form>
                  {rows.length > 0 ? (
                    <>
                      <SearchTable
                        isUpdate={isUpdate}
                        isStatus={isStatus}
                        allList={allCountry}
                        placeholder={"name"}
                        data={rows}
                        mapableData={mapableData}
                        searchProp={["name"]}
                        handleChangeActiveStatus={handleChangeActiveStatus}
                        handleChangeEdit={handleChangeEdit}
                      />
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={totalData}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </>
                  ) : (
                    <>
                      {isLoader ? (
                        <LoaderComponent />
                      ) : (
                        <NodataFound
                          onClick={() => {
                            openAddModal();
                            handleSelectBtn("add");
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
